import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

export default function ModalRate({
  openModal,
  handleCloseModal,
  setMainData2,
  mainData2,
  errorName,
  errorConcurrence,
  Submitmodal,
}) {
  function formatearNumeroConSeparador(numero) {
    // Convertir el número a string y eliminar cualquier separador de miles existente
    let numeroString = numero.toString().replace(/\./g, "");

    // Invertir el string para facilitar la inserción de separadores
    let numeroInvertido = numeroString.split("").reverse().join("");

    let numeroFormateado = "";

    // Insertar separadores de miles cada tres caracteres
    for (let i = 0; i < numeroInvertido.length; i++) {
      if (i > 0 && i % 3 === 0) {
        numeroFormateado += ".";
      }
      numeroFormateado += numeroInvertido[i];
    }

    // Revertir nuevamente el string para obtener el formato correcto
    return numeroFormateado.split("").reverse().join("");
  }
  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden" }}
      >
        <DialogTitle>Agregar nueva tarifa</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box sx={{ minWidth: { md: 500 }, p: 1 }}>
            <TextField
              label="Nombre Tarifa"
              InputProps={{
                sx: { borderRadius: 2, mb: 1 },
                endAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              id="name" // ID personalizado
              onChange={({ target }) =>
                setMainData2((adata) => ({ ...adata, name: target.value }))
              }
              value={mainData2.name}
              error={errorName.error}
              required
              type="text"
              variant="outlined"
              fullWidth
            />
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Cadencia</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                sx={{ borderRadius: 2, mb: 1 }}
                label="cadence"
                value={mainData2.concurrence}
                error={errorConcurrence.error}
                onChange={({ target }) =>
                  setMainData2((adata) => ({
                    ...adata,
                    concurrence: target.value,
                  }))
                }
              >
                <MenuItem value={"Mensual"}>Mensual</MenuItem>
                <MenuItem value={"Quincenal"}>Quincenal</MenuItem>
                <MenuItem value={"Por dia"}>Por dia</MenuItem>
                <MenuItem value={"Por hora"}>Por hora</MenuItem>
                <MenuItem value={"Por media hora"}>Por media hora</MenuItem>
                <MenuItem value={"Por paciente"}>Por paciente</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Valor"
              InputProps={{
                sx: { borderRadius: 2 },
              }}
              id="price" // ID personalizado
              onChange={(event) => {
                let val = formatearNumeroConSeparador(event.target.value);
                setMainData2((adata) => ({
                  ...adata,
                  price: val,
                }));
              }}
              value={mainData2.price}
              type="text"
              variant="outlined"
              fullWidth
              onKeyDown={(event) => {
                // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                if (
                  !/[0-9]/.test(event.key) &&
                  event.key !== "Backspace" &&
                  event.key !== "Delete" &&
                  event.key !== "ArrowLeft" &&
                  event.key !== "ArrowRight"
                ) {
                  event.preventDefault();
                }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={Submitmodal}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
