// Pages
import Login from "./pages/login";
import ResetPwd from "./pages/recovery-password";
import Register from "./pages/register";
import Logout from "./pages/login/logout";
import Profile from "./pages/profile";
import Center from "./pages/info_center";
import Client from "./pages/client";
import InfoClient from "./pages/info_client"
import Billclient from "./pages/billClient"
import Chat from "./pages/ExternalChats.jsx"
import Income from "./pages/incomeGeneral"
import IncomeInfo from "./pages/incomeInfo"
import Calendar from "./pages/calendar";
import DetailsAppointments from "./pages/infoAppointments"
import Reports from "./pages/reports"
import ProfessionalOverview from "./pages/ViewAdmin_Professionals"
import ProfessionalQuery from "./pages/ViewAdmin_Query";
import PrevLogin from "./pages/prev_login"
const routes = [
  {
    route: "/register",
    role: "auth",
    component: <Register />,
  },
  {
    route: "/prev_login",
    role: "auth",
    component: <PrevLogin />,
  },
  {
    route: "/login",
    role: "auth",
    component: <Login />,
  },
  {
    route: "/recuperar-contrasena",
    role: "auth",
    component: <ResetPwd />,
  },
  {
    route: "/logout",
    component: <Logout />,
  },
  //--------------professional routes----------------
  {
    route: "/profile",
    role: "user",
    component: <Profile/>,
  },
  {
    route: "/center/:centerid",
    role: "user",
    component: <Center/>,
  },
  {
    route: "/clients",
    role: "user",
    component: <Client/>,
  },
  {
    route: "/client/:clientid",
    role: "user",
    component: <InfoClient/>,
  },
  {
    route: "/client/bill/:clientid",
    role: "user",
    component: <Billclient/>,
  }
  ,
  {
    route: "/client/chat/:clientid",
    role: "user",
    component: <Chat/>,
  },
  {
    route: "/income",
    role: "user",
    component: <Income/>,
  }
  ,
  {
    route: "/income/info",
    role: "user",
    component: <IncomeInfo/>,
  },
  {
    route: "/",
    role: "user",
    component:  <Calendar/>,
  },
  {
    route: "/calendar/info",
    role: "user",
    component:  <DetailsAppointments/>,
  },
  {
    route: "/reports",
    role: "user",
    component:  <Reports/>,
  },
  //--------------admin routes----------------
  {
    route: "/professionals",
    role: "admin",
    component:  <ProfessionalOverview/>,
  },
  {
    route: "/professionals/query",
    role: "admin",
    component:  <ProfessionalQuery/>,
  },
];

export default routes;
