import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FormatearNumeroConSeparador from "../../utils/formatNumber";

export default function ModalPatient({
  openModal,
  handleCloseModal,
  centers,
  mainData,
  setMainData,
  handleSubmitmodal,
  mainDataError,
  VisuallyHiddenInput,
  CloudUploadIcon,
  loadApi,
  mainDataNewBill,
  setMainDataNewBill,
  enableAdditionalFields,
  setEnableAdditionalFields,
  newBillError,
}) {
  const [rates, setRates] = useState([]);
  useEffect(() => {
    loadApi(`rate/rates/${mainData.center_id}`, true, "get")
      .then((response) => {
        setRates(response.data);
      })
      .catch((e) => {});
  }, [mainData.center_id]);
  useEffect(() => {
    setMainDataNewBill((adata) => ({
      ...adata,
      center_id: mainData?.center_id,
      date: mainData?.date,
      method: mainData?.note,
    }));
  }, [mainData]);
  /*   useEffect(() => {
    if (mainData.note !== "") {
      setEnableAdditionalFields(false);
    } else {
      setEnableAdditionalFields(true);
    }
  }, [mainData.note]) */
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setEnableAdditionalFields(false);
    } else {
      setEnableAdditionalFields(true);
    }
  };
  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden" }}
      >
        <DialogTitle>Agregar un nuevo Registro</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: { xs: 100, md: 500 }, p: 1 }}>
            {centers?.length < 1 && (
              <Stack my={1}>
                <Alert severity="warning">
                  Aún no tienes centros, crea un nuevo centro{" "}
                  <Link className="link" href="/profile" color="inherit">
                    aquí
                  </Link>
                </Alert>
              </Stack>
            )}
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Centro</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    value={mainData.center_id}
                    error={mainDataError.center_id}
                    onChange={({ target }) =>
                      setMainData((adata) => ({
                        ...adata,
                        center_id: target.value,
                      }))
                    }
                    label="Centro"
                    required
                  >
                    <MenuItem value="" disabled>
                      Seleccione una opción...
                    </MenuItem>
                    {centers &&
                      (centers || []).map((row, index) => (
                        // recorrer centros row.id sera value y se mostrara row.name
                        <MenuItem
                          value={row.id}
                          onClick={() => {
                            setMainDataNewBill((adata) => ({
                              ...adata,
                              name_rate: "",
                              amount: "",
                            }));
                          }}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Fecha"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  variant="outlined"
                  fullWidth
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      date: target.value,
                    }))
                  }
                  value={mainData.date}
                  error={mainDataError.date}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-textarea"
                  label="Comentario"
                  multiline
                  rows={2}
                  fullWidth
                  onChange={({ target }) =>
                    setMainData((adata) => ({ ...adata, note: target.value }))
                  }
                  value={mainData?.note}
                />
              </Grid>

              {mainData.file && (
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="body1" paddingLeft={1}>
                      Documento seleccionado:{" "}
                      <Chip
                        label={mainData?.file.name}
                        onDelete={() => {
                          setMainData((adata) => ({
                            ...adata,
                            file: "",
                          }));
                        }}
                        variant="outlined"
                      />
                    </Typography>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  sx={{
                    mt: 1,
                    bgcolor: "secondary.main",
                    color: "primary.contrastText",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    ":hover": {
                      bgcolor: "#ebebeb",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    },
                  }}
                >
                  Adjuntar Documento
                  <VisuallyHiddenInput
                    type="file"
                    onChange={({ target }) => {
                      setMainData((adata) => ({
                        ...adata,
                        file: target.files[0],
                      }));
                    }}
                  />
                </Button>
              </Grid>
              <Grid xs={12} px={1} mt={1}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="¿Deseas agregar una factura?"
                  onChange={handleCheckboxChange}
                />
              </Grid>
              <Stack sx={{ width: "100%", my: 2 }}>
                <Typography variant="body1">Facturación </Typography>
              </Stack>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth disabled={enableAdditionalFields}>
                  <InputLabel id="demo-simple-select-label">
                    Nombre de la tarifa
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Nombre de la tarifa"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    //debe carmbiar para poner nombre tarifa
                    value={mainDataNewBill.name_rate}
                    error={newBillError.name_rate}
                    onChange={({ target }) =>
                      setMainDataNewBill((adata) => ({
                        ...adata,
                        name_rate: target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled>
                      Seleccione una opción...
                    </MenuItem>
                    {rates?.length < 1 && (
                      <MenuItem value={""}>
                        <Alert severity="warning">
                          El centro no tiene tarifas.
                        </Alert>
                      </MenuItem>
                    )}
                    {rates &&
                      (rates || [])?.map((row, index) => (
                        // recorrer centros row.id sera value y se mostrara row.name
                        <MenuItem
                          value={row?.name}
                          onClick={() => {
                            //setRate(row.price);
                            setMainDataNewBill((adata) => ({
                              ...adata,
                              amount: row.price,
                            }));
                          }}
                        >
                          {row?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Valor"
                  variant="outlined"
                  type="text"
                  id="amount"
                  fullWidth
                  disabled={enableAdditionalFields}
                  //debe carmbiar para poner nombre tarifa
                  value={FormatearNumeroConSeparador(mainDataNewBill.amount)}
                  onChange={({ target }) =>
                    setMainDataNewBill((adata) => ({
                      ...adata,
                      amount: target.value,
                    }))
                  }
                  error={newBillError.amount}
                  onKeyDown={(event) => {
                    // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth disabled={enableAdditionalFields}>
                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Estado"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    value={mainDataNewBill.status}
                    onChange={({ target }) =>
                      setMainDataNewBill((adata) => ({
                        ...adata,
                        status: target.value,
                      }))
                    }
                    error={newBillError.status}
                  >
                    <MenuItem value="Pendiente">Pendiente</MenuItem>
                    <MenuItem value="Cobrado">Cobrado</MenuItem>
                    <MenuItem value="Atrasado">Atrasado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSubmitmodal}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
