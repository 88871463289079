import { createTheme } from "@mui/material/styles";
import { esES } from '@mui/material/locale';
import LoadingButton from "@mui/lab/LoadingButton";

const textColor = "#161616";

const theme = createTheme({
  palette: {
    primary: {
      main: "#002B44",
      contrastText: textColor,
    },
    secondary: {
      main: "#F7F7F7",
      contrastText: "#000000"
    },
    text: {
      primary: textColor,
    },
    background: {
      default: "#F5F5F5",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    color: "#260944",
  },
  components: {
    
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "gray"

        }, 
        
      },
      
        
    },
    MuiMenuItem: {
      defaultProps: {
        value: "seleccinar", // Valor por defecto
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: 224, // Limita la altura máxima del menú a 224px (aproximadamente 5 opciones)
          '&::-webkit-scrollbar': {
            width: '6px', // Ancho del scrollbar
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b0b0b0', // Color del pulgar del scrollbar
            borderRadius: '10px', // Bordes redondos
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#999999', // Color del pulgar del scrollbar al pasar el ratón
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f0f0f0', // Color de la pista del scrollbar
            borderRadius: '10px', // Bordes redondos
          },
        
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            fontWeight: 400,
          },
          label: {
            fontWeight: 500,
            color: "#a7a7a7bd!important"
          },
          "& .Mui-disabled": {
            "-webkit-text-fill-color": "#a7a7a7bd!important",
            color: "#a7a7a7bd!important",
            fontWeight: 400
          },
          "fieldset": {
            borderColor: "#b0b0b0d9"
          },
          "input::placeholder": {
            color: "#777777",
          },
          "& .MuiInput-root::before": {
            // borderBottom: "1px solid #fff"
          }
        },
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          // color: "#fff"
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 10000,
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "1px 1px 6px -3px #898989",
          border: "1px solid #80808040",
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#9eb2b38a!important",
        }
      },
      defaultProps: {
        variant: "fullWidth",
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontWeight: "600!important"
        }
      }
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: "error" },
          style: {
            backgroundColor: "rgb(255 184 184 / 100%)",
            fontWeight: 500,
            svg: {
              color: "#dd0000"
            }
          }
        },
        {
          props: { severity: "success" },
          style: {
            backgroundColor: "rgb(177 247 177 / 100%)",
            fontWeight: 500,
            svg: {
              color: "#00a31d"
            }
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 500,

          fontSize: "0.938rem",
        }
      },
      defaultProps: {
        variant: "contained",
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "#fff"
          }
        }
      ]
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: textColor
        }
      },
      variants: [
        {
          props: { className: "link" },
          style: {
            cursor: "pointer",
            textDecoration: "underline",
            transition: "color 0.3s ease",
            ":hover": {
              color: "#999999",
            }
          }
        }
      ],
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderRadius: '8px', // Aplica border-radius al menú desplegable
        },
        listbox: {
          borderRadius: '8px', // Aplica border-radius a la lista de opciones
        },
        inputRoot: {
          '&.MuiOutlinedInput-root': {
            borderRadius: '8px', // Aplica border-radius al input
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px', // Aplica border-radius al input
          },
        },
        popper: {
          borderRadius: '8px', // Aplica border-radius al popper
        },
      },
    },
  },
  
},esES);

export default theme;
