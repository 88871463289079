import { Box, Button, Stack, Typography } from "@mui/material";
import OKIcon from "../../images/ok.svg";
import Logo from "../../images/Logoazul.svg";
import { Link } from "react-router-dom";

export default function RegisterSuccess({ email }) {
  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        sx={{
          backgroundColor: "background.paper",
          padding: "20px",
          animation: "entryX 0.5s ease forwards",
          position: "relative",
          boxShadow: "1px 1px 6px #e1e1e1f9",
          borderRadius: 4,
          width: "100%",
          maxWidth: {
            sm: "510px",
            xs: "100%",
          },
        }}
      >
        <img src={Logo} alt="" width={130} />
        <img src={OKIcon} alt="" height={130} />
        <Typography variant="h6">¡Contraseña Restablecida!</Typography>
        <Typography fontWeight={500} align="center">
          Ya puedes iniciar sesión con tu nueva contraseña.
        </Typography>
        <Box py={2} width="100%">
          <Button
            fullWidth
            LinkComponent={Link}
            to="/login"
            sx={{
              py: 1.3,
              fontSize: "1.2rem",
            }}
          >
            Iniciar Sesión
          </Button>
        </Box>
      </Stack>
    </>
  );
}
