import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

export default function Deletecenter({
  setOpenModaldelete,
  openModaldelete,
  deletecenter,
}) {
  const handleCloseModaldelete = () => {
    setOpenModaldelete(null);
  };

  return (
    <>
      <Dialog
        open={!!openModaldelete}
        keepMounted
        onClose={handleCloseModaldelete}
      >
        <DialogTitle>{"Eliminar Centro"}</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 500 }}>
            <DialogContentText id="deletecenter">
              <Typography sx={{ my: 1 }}>
                ¿Deseas eliminar este centro?
              </Typography>
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModaldelete}>Cancelar</Button>
          <Button
            color="error"
            onClick={() => {
              handleCloseModaldelete();
              deletecenter(openModaldelete);
            }}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
