import { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  Box,
  Avatar,
  ListItemAvatar,
  Stack,
  Container,
  Tooltip,
  ListItemIcon,
  Divider,
  MenuItem,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { Link } from "react-router-dom";
import headerOptions from "../utils/headerOptionsAdmin";
import { useAuth } from "../hooks/useAuth";
import Logo from "../images/logowhite.svg";
import profile from "../images/perfil.svg"

function GlobalHeaderAdmin({LogoProfile,previewImage}) {
  
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { loggedAuth } = useAuth();
  const [options, setOptions] = useState(
    loggedAuth ? headerOptions.logged : headerOptions.default
  );
  const slicedOptions = options.slice(0, options.length - 1);
  useEffect(() => {
    setOptions(!!loggedAuth ? headerOptions.logged : headerOptions.default);
  }, [loggedAuth]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };
  return (
    <>
      <AppBar id="GlobalHeader" position="static">
        <Container
          sx={{
            py: 1,
          }}
        >
          <Stack direction="row" >
            <Stack direction="row" spacing={2} width="20%">
              {!!loggedAuth && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{
                    color: "#fff",
                    display: {
                      xs: "inherit",
                      lg: "none",
                    },
                  }}
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Box component={Link} to="/">
                <img src={Logo} width={200} height="100%" />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="center" width="60%">
              {slicedOptions.map((item, i) => (
                <Link key={i} to={item.route}>
                  <ListItem>
                    <Box
                      spacing={1}
                      sx={{
                        display: {
                          xs: "none",
                          md: "none",
                          lg: "inherit",
                          xl: "inherit",
                        },
                        borderBottom: "3px solid",
                        alignItems: "center",
                        borderColor:
                          window.location.pathname === item.route
                            ? "#fff"
                            : "secondary.main",
                        width: "100%",
                        pb: 1,
                        svg: {
                          color: "#fff!important",
                        },
                      }}
                    >
                      <Typography color="white">{item.title}</Typography>
                    </Box>
                  </ListItem>
                </Link>
              ))}
            </Stack>
            
             {!!loggedAuth && (
            <Stack
            justifyContent="right"
            width="20%"
              direction="row"
              sx={{
                display: {
                  xs: "none",
                  lg: "inherit",
                },
              }}
              spacing={3}
            >
              <Tooltip>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar src={previewImage || LogoProfile  ||  profile} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ overflow: "hidden", mt: "45px", position: "absolute" }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                 
                  
                  <MenuItem  onClick={handleCloseUserMenu}>
                    {options.map((row, key) => {
                if (row.route !== "/logout") return undefined;

                return (
                  <Box
                    key={key}
                    component={Link}
                    to={row.route}
                    display="flex"
                    
                    gap="3px"
                    sx={{
                      
                      cursor:
                        window.location.pathname === row.route
                          ? "default"
                          : "pointer",
                      
                    }}
                  >
                    <row.icon />
                    <Typography >{row.title}</Typography>
                  </Box>
                );
              })}
                  </MenuItem>
              </Menu>

              
            </Stack>
            )
          }  
          </Stack>
        </Container>
      </AppBar>
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box bgcolor="black" px={2} height="100vh" overflow="hidden">
            <List sx={{ height: "100%", p: 0, pt: 2 }}>
              <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                <Box>
                  <ListItem sx={{ my: 1 }}>
                    <ListItemAvatar>
                      <Avatar
                        src={Logo}
                        sx={{
                          width: "180px",
                          height: "auto",
                          borderRadius: 0,
                        }}
                      />
                    </ListItemAvatar>
                  </ListItem>
                  {options.map((item, i) => (
                    <Link key={i} to={item.route}>
                      <ListItem>
                        <Stack
                          spacing={1}
                          direction="row"
                          sx={{
                            borderBottom: "3px solid",
                            borderColor:
                              window.location.pathname === item.route
                                ? "#fff"
                                : "secondary.main",
                            width: "100%",
                            py: 1,
                            svg: {
                              color: "#fff!important",
                            },
                          }}
                        >
                          <item.icon />
                          <Typography color="white">{item.title}</Typography>
                        </Stack>
                      </ListItem>
                    </Link>
                  ))}
                </Box>
              </Stack>
            </List>
          </Box>
        </div>
      </Drawer>
    </>
  );
}

export default GlobalHeaderAdmin;
