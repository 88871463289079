import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function DeleteAppointment({
  openModaldelete,
  setOpenModaldelete,

  deleteAppoint,
  setOpenModalInfoAppo,
}) {
  return (
    <>
      <Dialog
        open={!!openModaldelete}
        keepMounted
        onClose={() => {
          setOpenModaldelete(false);
        }}
        sx={{ zIndex: 50000 }}
      >
        <DialogTitle>{"Eliminar Registro"}</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 500 }}>
            <DialogContentText id="deletecenter">
              ¿Deseas eliminar esta cita?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModaldelete(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            color="error"
            onClick={() => {
              setOpenModalInfoAppo(false);
              setOpenModaldelete(false);
              deleteAppoint(openModaldelete);
            }}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
