import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Stack,
  IconButton,
  Card,
  Alert,
  Chip,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import ChatIcon from "@mui/icons-material/Chat";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ModalPatient from "./modalPatient";
import dateToInput from "../../utils/DateToInput";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteRegister from "./deleteRegister";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import BackClients from "./back";
import DeleteRegisterFile from "./deleteFile";
export default function InfoClient() {
  const { clientid } = useParams();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [centers, setCenters] = useState();
  const [info, setInfo] = useState({
    address: null,
    email: null,
    lastname: null,
    name: null,
    national_id: "",
    note: null,
    phone: null,
  });
  const [infoError, setInfoError] = useState({
    address: false,
    email: false,
    lastname: false,
    name: false,
    national_id: false,
    note: false,
    phone: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [openModaldelete, setOpenModaldelete] = useState(false);
  const [openModaldeleteFile, setOpenModaldeleteFile] = useState(false);
  const [namecomplete, setNamecomplete] = useState("");
  const [photo_profile, setPhoto_profile] = useState();
  const [registros, setRegistros] = useState([]);
  const [enableAdditionalFields, setEnableAdditionalFields] = useState(true);

  const [mainData, setMainData] = useState({
    center_id: "",
    date: "",
    note: "",
    file: "",
  });
  const [mainDataError, setMainDataError] = useState({
    center_id: false,
    date: false,
    note: false,
    file: false,
  });

  const [mainDataNewBill, setMainDataNewBill] = useState({
    center_id: "",
    client_id: clientid,
    name_rate: "",
    amount: "",
    date: "",
    status: "",
    method: "",
  });
  const [newBillError, setNewBillError] = useState({
    center_id: false,
    client_id: false,
    name_rate: false,
    amount: false,
    date: false,
    status: false,
    method: false,
  });
  useEffect(() => {
    loadApi("center/centers", true, "get")
      .then((response) => {
        setCenters(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);
  const [centersfiltred, setCentersfiltred] = useState();
  useEffect(() => {
    loadApi("center/centers_on", true, "get")
      .then((response) => {
        setCentersfiltred(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);
  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setMainDataError({
      center_id: false,
      date: false,
      note: false,
      file: false,
    });
    setMainData({
      center_id: "",
      date: "",
      note: "",
      file: "",
    });
    setMainDataNewBill({
      center_id: "",
      client_id: clientid,
      name_rate: "",
      amount: "",
      date: "",
      status: "",
      method: "",
    });
    setNewBillError({
      center_id: false,
      client_id: false,
      name_rate: false,
      amount: false,
      date: false,
      status: false,
      method: false,
    });
  };
  const handleCloseModaldelete = () => {
    setOpenModaldelete(false);
  };
  // consulta la foto del profesional
  useEffect(() => {
    setOpenbackd(true);
    loadApi("profile", true, "get")
      .then((response) => {
        setPhoto_profile(response.data.photo_profile.url);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  // consulta la iinformacion personal del paciente
  useEffect(() => {
    setOpenbackd(true);
    loadApi(`client/client/${clientid}`, true, "get")
      .then((response) => {
        setInfo(response.data);

        setNamecomplete(response.data.name + " " + response.data.lastname);
      })
      .catch((e) => {
        showSnack(e.message);
        if (e.message) {
          setTimeout(() => {
            navigate("/clients");
          }, 800);
        }
        setOpenbackd(true);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  useEffect(() => {
    setOpenbackd(true);
    loadApi(`querie/queries/${clientid}`, true, "get")
      .then((response) => {
        setRegistros(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  const handleSubmitinfo = async (e) => {
    e.preventDefault();
    if (!ValidateInfo(info, setInfoError)) return;
    setOpenbackd(true);
    loadApi(`client/update/${clientid}`, true, "put", {
      ...info,
    })
      .then((response) => {
        showSnack(response.data.message, "success");
        setInfoError({
          address: false,
          email: false,
          lastname: false,
          name: false,
          national_id: false,
          note: false,
          phone: false,
        });
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setNamecomplete(info.name + " " + info.lastname);
        setOpenbackd(false);
      });
  };
  const handleSubmitnote = async (e) => {
    e.preventDefault();

    setOpenbackd(true);

    loadApi(`client/update_note/${clientid}`, true, "put", {
      note: info.note,
    })
      .then((response) => {
        showSnack(response.data.message, "success");
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const handleSubmitmodal = async (e) => {
    e.preventDefault();
    if (!ValidateModal(mainData, setMainDataError)) return;

    if (enableAdditionalFields === true) {
      //se manda sin factura
      setOpenModal(false);
      setOpenbackd(true);
      loadApi(
        `querie/${clientid}`,
        true,
        "post",
        {
          ...mainData,
        },
        true
      )
        .then((response) => {
          setRegistros((adata) => {
            const newRecordData = [...adata, response.data.recordData];
            newRecordData.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newRecordData;
          });
          setMainData({
            center_id: "",
            date: "",
            note: "",
          });
          showSnack(response.data.message, "success");
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
          handleCloseModal();
        });
    } else {
      // se mandan con factura
      if (!ValidateModalAndBill(mainDataNewBill, setNewBillError)) return;
      setOpenModal(false);
      setOpenbackd(true);
      loadApi(
        `querie/${clientid}`,
        true,
        "post",
        {
          ...mainData,
        },
        true
      )
        .then((response) => {
          setRegistros((adata) => {
            const newRecordData = [...adata, response.data.recordData];
            newRecordData.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newRecordData;
          });
          setMainData({
            center_id: "",
            date: "",
            note: "",
          });
          showSnack(response.data.message, "success");
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
          handleCloseModal();
        });
      //-------
      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    }
  };
  //funcion para darle formato al nombre del paciente en mayusculas al inicio
  function capitalizarCadena(cadena) {
    // Dividir la cadena en palabras
    let palabras = cadena.toLowerCase().split(" ");

    // Capitalizar la primera letra de cada palabra
    let palabrasCapitalizadas = (palabras || []).map((palabra) => {
      return palabra.charAt(0).toUpperCase() + palabra.slice(1);
    });

    // Unir las palabras capitalizadas en una sola cadena
    let cadenaCapitalizada = palabrasCapitalizadas.join(" ");

    // Retornar la cadena capitalizada
    return cadenaCapitalizada;
  }
  const VisuallyHiddenInput = (props) => (
    <input
      type="file"
      style={{ display: "none" }}
      accept=".xlsx,.xls,.doc,.docx,.pdf,.txt,image/*"
      {...props}
    />
  );
  const deleteregister = (id) => {
    setOpenbackd(true);
    loadApi(`querie/delete/${id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success", 1000);
        //eliminar por id del registro
        setRegistros((adata) => adata.filter((aitem) => aitem.id !== id));
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const updateRegister = (id, index) => {
    setOpenbackd(true);

    loadApi(
      `querie/update_querie/${id}`,
      true,
      "put",
      {
        ...registros[index],
      },
      true
    )
      .then((response) => {
        showSnack(response.data.message, "success");
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  useEffect(() => {
    if (!info.name && info.name !== null) {
      setInfoError((adata) => ({
        ...adata,
        name: true,
      }));
    } else {
      setInfoError((adata) => ({
        ...adata,
        name: false,
      }));
    }
    if (!info.lastname && info.lastname !== null) {
      setInfoError((adata) => ({
        ...adata,
        lastname: true,
      }));
    } else {
      setInfoError((adata) => ({
        ...adata,
        lastname: false,
      }));
    }
  }, [info.name, info.lastname]);
  const deleteregisterFile = (id, index) => {
    setRegistros((adata) => {
      let newData = [...adata];
      newData[index].file = "";

      return newData;
    });
    setOpenbackd(true);
    loadApi(
      `querie/update_querie/${id}`,
      true,
      "put",
      {
        ...registros[index],
      },
      true
    )
      .then((response) => {
        showSnack(response.data.message, "success");
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container
        maxWidth="lg"
        sx={{ alignItems: "center", background: "#FFFFFF", p: 2 }}
      >
        <BackClients />
        <Grid container direction="row">
          <Grid item xs={12} sm={6} md={4} lg={4} container alignItems="center">
            <Typography variant="h5">
              {capitalizarCadena(namecomplete)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8} py={2}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
              sx={{
                justifyContent: { xs: "left", md: "flex-end" },

                flexWrap: "wrap",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  display: { xs: "inherit", sm: "none" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                }}
                onClick={() => {
                  navigate(`/client/${clientid}`);
                }}
              >
                <Box>
                  <Typography color="black">General</Typography>
                </Box>
              </Button>
              <Button
                variant="text"
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },

                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white",
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80%", // Full width on extra small screens
                      sm: "auto", // Auto width on medium screens and up
                    },
                    borderBottom: "3px solid",
                    alignItems: "center",

                    pb: 1,
                    svg: {
                      color: "#fff!important",
                    },
                  }}
                  onClick={() => {
                    navigate(`/client/${clientid}`);
                  }}
                >
                  <Typography color="black">General</Typography>
                </Box>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  display: { xs: "inherit", sm: "none" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                }}
                onClick={() => {
                  navigate(`/client/bill/${clientid}`);
                }}
              >
                <Box>
                  <Typography color="black">Facturación</Typography>
                </Box>
              </Button>
              <Button
                variant="text"
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white", // Remove background color change
                    //opacity: 0.8, // Apply opacity change
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80%", // Full width on extra small screens
                      md: "auto", // Auto width on medium screens and up
                    },
                    borderBottom: "3px solid",
                    alignItems: "center",

                    pb: 1,
                    svg: {
                      color: "#fff!important",
                    },
                  }}
                  onClick={() => {
                    navigate(`/client/bill/${clientid}`);
                  }}
                >
                  <Typography color="black">Facturación</Typography>
                </Box>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  display: { xs: "inherit", sm: "none" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                }}
                onClick={() => {
                  navigate(`/client/chat/${clientid}`);
                }}
              >
                <Box>
                  <Typography color="black">Comunicaciones</Typography>
                </Box>
              </Button>
              <Button
                variant="text"
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white", // Remove background color change
                    //opacity: 0.8, // Apply opacity change
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80%", // Full width on extra small screens
                      md: "auto", // Auto width on medium screens and up
                    },
                    borderBottom: "3px solid",
                    alignItems: "center",

                    pb: 1,
                    svg: {
                      color: "#fff!important",
                    },
                  }}
                  onClick={() => {
                    navigate(`/client/chat/${clientid}`);
                  }}
                >
                  <Typography color="black">Comunicaciones</Typography>
                </Box>
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Divider />

        <Grid container direction="row" spacing={2} mt={1} mb={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              justifyContent="space-between"
              p={0}
              sx={{
                height: "570px",
              }}
            >
              <Stack justifyContent="center" alignItems="center" margin={2}>
                <Typography variant="h6" textAlign="center">
                  Información del paciente
                </Typography>
              </Stack>
              <Stack p={2} spacing={2}>
                <TextField
                  id="outlined-basic"
                  label="Nombre"
                  variant="outlined"
                  value={info && info.name}
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={({ target }) =>
                    setInfo((adata) => ({
                      ...adata,
                      name: target.value,
                    }))
                  }
                  required
                  error={infoError?.name}
                />

                <TextField
                  id="outlined-basic"
                  label="Apellidos"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  value={info && info.lastname}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) =>
                    setInfo((adata) => ({
                      ...adata,
                      lastname: target.value,
                    }))
                  }
                  error={infoError.lastname}
                  required
                />

                <TextField
                  label="N° Identificación"
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  disabled={true}
                  value={info && info.national_id}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  id="outlined-basic"
                  label="Celular"
                  variant="outlined"
                  type="text"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  value={info && info.phone}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) =>
                    setInfo((adata) => ({
                      ...adata,
                      phone: target.value,
                    }))
                  }
                  onKeyDown={(event) => {
                    // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  label="Correo electrónico"
                  id="outlined-basic"
                  variant="outlined"
                  type="email"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  value={info && info.email}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) =>
                    setInfo((adata) => ({
                      ...adata,
                      email: target.value,
                    }))
                  }
                />

                <TextField
                  id="outlined-basic"
                  label="Dirección"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  value={info && info.address}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) =>
                    setInfo((adata) => ({
                      ...adata,
                      address: target.value,
                    }))
                  }
                />
              </Stack>
              <Stack px={2}>
                <Button onClick={handleSubmitinfo}>
                  {" "}
                  <SaveOutlinedIcon sx={{ marginInlineEnd: 1 }} />
                  Guardar
                </Button>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              justifyContent="space-between"
              sx={{
                height: "570px",
                px: 2,
              }}
            >
              <Stack spacing={2} height="100%" justifyContent="space-between">
                <Stack pt={2}>
                  <Typography variant="h6" textAlign="center">
                    Notas
                  </Typography>
                </Stack>
                <Stack>
                  <TextField
                    id="outlined-textarea"
                    variant="outlined"
                    placeholder="Notas acerca del paciente"
                    multiline
                    rows="16.9"
                    sx={{
                      height: "100%",
                    }}
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                    fullWidth
                    onChange={({ target }) =>
                      setInfo((adata) => ({
                        ...adata,
                        note: target.value,
                      }))
                    }
                    value={info && info?.note}
                  />
                </Stack>

                <Stack pb={2.4}>
                  <Button onClick={handleSubmitnote}>
                    {" "}
                    <SaveOutlinedIcon sx={{ marginInlineEnd: 1 }} />
                    Guardar
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          textAlign="center"
          marginY={3}
          justifyContent="space-between"
          px={0.5}
        >
          <Typography variant="h5">Registros</Typography>
          <Button
            variant="contained"
            onClick={handleClickOpenModal}
            sx={{
              bgcolor: "secondary.main",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
            <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
              Nuevo Registro
            </Typography>
          </Button>
        </Stack>
        {registros?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">El paciente no tiene registros.</Alert>
          </Stack>
        )}
        {registros &&
          (registros || [])?.map((row, index) => (
            <Card sx={{ paddingX: 2, marginBottom: 2 }}>
              <Grid container direction="row" spacing={2} sx={{ my: 1 }}>
                <Grid item xs={12} sm={12} md={4} lg={4} px={2}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">
                      Centro
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      sx={{ borderRadius: 2, mb: 1 }}
                      InputProps={{
                        sx: { borderRadius: 2 },
                      }}
                      type="text"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={registros[index].center_id}
                      onChange={({ target }) =>
                        setRegistros((adata) => {
                          let newData = [...adata];
                          newData[index].center_id = target.value;

                          return newData;
                        })
                      }
                      label="Centro"
                      required
                    >
                      <MenuItem value="" disabled>
                        Seleccione una opción...
                      </MenuItem>
                      {centers &&
                        (centers || [])?.map((row, index) => (
                          // recorrer centros row.id sera value y se mostrara row.name
                          <MenuItem value={row.id}>{row.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} px={2}>
                  <TextField
                    label="Fecha"
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                    variant="outlined"
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={dateToInput(registros[index].date)}
                    onChange={({ target }) =>
                      setRegistros((adata) => {
                        let newData = [...adata];
                        newData[index].date = target.value;
                        return newData;
                      })
                    }
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} px={2}>
                  {registros[index]?.file < 1 && (
                    <Stack>
                      <Alert
                        sx={{
                          minHeight: { md: "40px" },
                          backgroundColor: "white",
                        }}
                        severity="info"
                      >
                        Sin documento
                      </Alert>
                    </Stack>
                  )}

                  {registros[index]?.file.length !== 0 ? (
                    <div>
                      <Typography variant="body1">
                        <Chip
                          sx={{
                            width: "100%",
                            minHeight: { md: "55px" },
                            borderRadius: 2,
                          }}
                          icon={<FileDownloadIcon />}
                          label={registros[index].file.name}
                          onClick={() => {
                            const fileUrl = registros[index].file.url; // Asegúrate de que esto sea una URL válida como string
                            if (typeof fileUrl === "string") {
                              const a = document.createElement("a");
                              a.href = fileUrl;
                              a.download = registros[index].file.name;
                              document.body.appendChild(a);
                              a.click();
                              document.body.removeChild(a);
                            } else {
                              console.error("La URL del archivo no es válida");
                            }
                          }}
                          onDelete={() => {
                            setOpenModaldeleteFile({
                              id: registros[index].id,
                              index: index,
                            });
                          }}
                          variant="outlined"
                        />
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} px={2}>
                  <TextField
                    label="Nota"
                    multiline
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                    type="text"
                    variant="outlined"
                    fullWidth
                    rows={2}
                    InputLabelProps={{ shrink: true }}
                    value={registros[index].note}
                    onChange={({ target }) =>
                      setRegistros((adata) => {
                        let newData = [...adata];
                        newData[index].note = target.value;
                        return newData;
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} px={4} align="center">
                  <IconButton
                    aria-label="load"
                    size="large"
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    fullWidth
                    color="secondary"
                    sx={{
                      bgcolor: "#e89823!important",

                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      ":hover": {
                        bgcolor: "#e89823!important",
                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      },
                    }}
                  >
                    <CloudUploadIcon fontSize="inherit" />
                    <VisuallyHiddenInput
                      type="file"
                      onChange={({ target }) => {
                        const updateRegister = (id, data) => {
                          setOpenbackd(true);
                          loadApi(
                            `querie/update_querie/${id}`,
                            true,
                            "put",
                            {
                              ...data,
                            },
                            true
                          )
                            .then((response) => {
                              showSnack(response.data.message, "success");
                            })
                            .catch((e) => {
                              showSnack(e.message);
                            })
                            .finally((e) => {
                              setOpenbackd(false);
                            });
                        };

                        let finalTempData;

                        let file = target.files[0];
                        if (file) {
                          setRegistros((adata) => {
                            let newData = [...adata];
                            newData[index].file = target.files[0];
                            finalTempData = newData[index];

                            return newData;
                          });

                          updateRegister(registros[index].id, finalTempData);
                        }
                      }}
                    />
                  </IconButton>

                  <IconButton
                    aria-label="delete"
                    size="large"
                    onClick={() => {
                      setOpenModaldelete(registros[index].id);
                    }}
                    color="secondary"
                    sx={{
                      mx: 1,
                      bgcolor: "#D32F2F!important",

                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      ":hover": {
                        bgcolor: "#D32F2F!important",
                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      },
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    aria-label="save"
                    size="large"
                    onClick={() => {
                      updateRegister(registros[index].id, index);
                    }}
                    color="secondary"
                    sx={{
                      bgcolor: "primary.main",

                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      ":hover": {
                        bgcolor: "primary.main",
                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      },
                    }}
                  >
                    <SaveOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
              </Grid>
            </Card>
          ))}
      </Container>
      <ModalPatient
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        centers={centersfiltred}
        mainData={mainData}
        setMainData={setMainData}
        handleSubmitmodal={handleSubmitmodal}
        mainDataError={mainDataError}
        VisuallyHiddenInput={VisuallyHiddenInput}
        CloudUploadIcon={CloudUploadIcon}
        loadApi={loadApi}
        mainDataNewBill={mainDataNewBill}
        setMainDataNewBill={setMainDataNewBill}
        enableAdditionalFields={enableAdditionalFields}
        setEnableAdditionalFields={setEnableAdditionalFields}
        newBillError={newBillError}
      />
      <DeleteRegister
        openModaldelete={openModaldelete}
        handleCloseModaldelete={handleCloseModaldelete}
        deleteregister={deleteregister}
      />
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <DeleteRegisterFile
        openModaldeleteFile={openModaldeleteFile}
        setOpenModaldeleteFile={setOpenModaldeleteFile}
        deleteregisterFile={deleteregisterFile}
      />
    </>
  );
}
function ValidateModal(mainData, setMainDataError) {
  var final = false;
  const newAdata = {
    center_id: !mainData.center_id,
    date: !mainData.date,
  };

  setMainDataError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
function ValidateModalAndBill(mainDataNewBill, setNewBillError) {
  var final = false;
  const newAdata = {
    center_id: !mainDataNewBill.center_id,
    client_id: !mainDataNewBill.client_id,
    name_rate: !mainDataNewBill.name_rate,
    amount: !mainDataNewBill.amount,
    date: !mainDataNewBill.date,
    status: !mainDataNewBill.status,
  };

  setNewBillError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
function ValidateInfo(info, setInfoError) {
  var final = false;
  const newAdata = {
    lastname: !info.lastname,
    name: !info.name,
    national_id: !info.national_id,
  };

  setInfoError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
