import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputBase,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  IconButton,
  ListItem,
  Card,
  Chip,
  Alert,
} from "@mui/material";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import ChatIcon from "@mui/icons-material/Chat";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import dateToInput from "../../utils/DateToInput";

import capitalizarCadena from "../../utils/Cadena";
import DateCusmton from "../../utils/DateCusmton";
import BackClients from "../info_client/back";
export default function ExternalChats() {
  const { clientid } = useParams();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [photo_profile, setPhoto_profile] = useState();
  const [namecomplete, setNamecomplete] = useState("");
  const [chats, setChats] = useState([]);
  // consulta la foto del profesional
  useEffect(() => {
    setOpenbackd(true);
    loadApi("profile", true, "get")
      .then((response) => {
        setPhoto_profile(response.data.photo_profile.url);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  // consulta la iinformacion personal del paciente
  useEffect(() => {
    setOpenbackd(true);
    loadApi(`client/client/${clientid}`, true, "get")
      .then((response) => {
        //setInfo(response.data);
        setNamecomplete(response.data.name + " " + response.data.lastname);
      })
      .catch((e) => {
        showSnack(e.message);
        if (e.message) {
          setTimeout(() => {
            navigate("/clients");
          }, 800);
        }
        setOpenbackd(true);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  useEffect(() => {
    setOpenbackd(true);
    loadApi(`externalChats/sentMessages/${clientid}`, true, "get")
      .then((response) => {
        setChats(response.data.external_chats);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <BackClients />
        <Grid container direction="row">
          <Grid item xs={12} sm={5} md={4} lg={4} container alignItems="center">
            <Typography variant="h5">
              {capitalizarCadena(namecomplete)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={8} lg={8} py={2}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
              sx={{
                justifyContent: { xs: "left", md: "flex-end" },

                flexWrap: "wrap",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  display: { xs: "inherit", sm: "none" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                }}
                onClick={() => {
                  navigate(`/client/${clientid}`);
                }}
              >
                <Box>
                  <Typography color="black">General</Typography>
                </Box>
              </Button>
              <Button
                variant="text"
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },

                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white",
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80%", // Full width on extra small screens
                      sm: "auto", // Auto width on medium screens and up
                    },
                    borderBottom: "3px solid",
                    alignItems: "center",

                    pb: 1,
                    svg: {
                      color: "#fff!important",
                    },
                  }}
                  onClick={() => {
                    navigate(`/client/${clientid}`);
                  }}
                >
                  <Typography color="black">General</Typography>
                </Box>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  display: { xs: "inherit", sm: "none" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                }}
                onClick={() => {
                  navigate(`/client/bill/${clientid}`);
                }}
              >
                <Box>
                  <Typography color="black">Facturación</Typography>
                </Box>
              </Button>
              <Button
                variant="text"
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white", // Remove background color change
                    //opacity: 0.8, // Apply opacity change
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80%", // Full width on extra small screens
                      md: "auto", // Auto width on medium screens and up
                    },
                    borderBottom: "3px solid",
                    alignItems: "center",

                    pb: 1,
                    svg: {
                      color: "#fff!important",
                    },
                  }}
                  onClick={() => {
                    navigate(`/client/bill/${clientid}`);
                  }}
                >
                  <Typography color="black">Facturación</Typography>
                </Box>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  display: { xs: "inherit", sm: "none" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                }}
                onClick={() => {
                  navigate(`/client/chat/${clientid}`);
                }}
              >
                <Box>
                  <Typography color="black">Comunicaciones</Typography>
                </Box>
              </Button>
              <Button
                variant="text"
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white", // Remove background color change
                    //opacity: 0.8, // Apply opacity change
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80%", // Full width on extra small screens
                      md: "auto", // Auto width on medium screens and up
                    },
                    borderBottom: "3px solid",
                    alignItems: "center",

                    pb: 1,
                    svg: {
                      color: "#fff!important",
                    },
                  }}
                  onClick={() => {
                    navigate(`/client/chat/${clientid}`);
                  }}
                >
                  <Typography color="black">Comunicaciones</Typography>
                </Box>
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Divider sx={{ mb: 2 }} />
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 1,
            borderRadius: 1,
            my: 1,
            display: { xs: "none", sm: "none", md: "inherit" },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={2} lg={2} align="center">
              <Typography variant="body1">Canal</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid item xs={12} sm={12} md={3} lg={3} align="center">
              <Typography variant="body1">Correo/Celular</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid item xs={12} sm={12} md={2} lg={2} align="center">
              <Typography variant="body1">Fecha</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid item xs={12} sm={12} md={4} lg={4.8} align="center">
              <Typography variant="body1">Mensaje</Typography>
            </Grid>
          </Grid>
        </Box>
        {chats?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">El paciente no tiene mensajes.</Alert>
          </Stack>
        )}
        {chats &&
          (chats || [])?.map((row, index) => (
            <Box
              sx={{
                border: "1px solid #e0e0e0",
                padding: 2,
                borderRadius: 1,
                my: 1,
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} md={2} lg={2} align="center">
                  <Typography variant="body1" sx={{ display: { md: "none" } }}>
                    Canal
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {row?.channel}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                <Grid item xs={12} sm={12} md={3} lg={3} align="center">
                  <Typography variant="body1" sx={{ display: { md: "none" } }}>
                    Correo/Celular
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {" "}
                    {row.channel === "Email" ? row?.email : row.phone}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                <Grid item xs={12} sm={12} md={2} lg={2} align="center">
                  <Typography variant="body1" sx={{ display: { md: "none" } }}>
                    Fecha
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {DateCusmton(row?.date)}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                <Grid item xs={12} sm={12} md={4} lg={4.8} align="center">
                  <Typography variant="body1" sx={{ display: { md: "none" } }}>
                    Mensaje
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {row?.text}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
