import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ResetPwdIcon from "../../images/resetpassword.svg";
import Logo from "../../images/Logoazul.svg";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useApi from "../../hooks/useApi";
import { LoadingButton } from "@mui/lab";

export default function RecoveryPWD1({ token, setStep, showSnack }) {
  const [mainData, setMainData] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const { loadApi, loadingApi } = useApi();
  const Submit = async (e) => {
    e.preventDefault();
    loadApi("reset_password", false, "post", {
      token,
      password: mainData,
    })
      .then(() => {
        setStep(4);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  };
  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        component="form"
        onSubmit={Submit}
        sx={{
          backgroundColor: "background.paper",
          padding: "20px",
          animation: "entryX 0.5s ease forwards",
          position: "relative",
          boxShadow: "1px 1px 6px #e1e1e1f9",
          borderRadius: 4,
          width: "100%",
          maxWidth: {
            sm: "510px",
            xs: "100%",
          },
        }}
      >
        <img src={Logo} alt="" width={130} />
        <img src={ResetPwdIcon} alt="" height={130} />
        <Typography variant="h6">Ingresa tu nueva contraseña</Typography>
        <TextField
          type={passwordVisible ? "text" : "password"}
          variant="outlined"
          name="password"
          value={mainData}
          label="Mira tu contraseña antes de enviar"
          fullWidth
          placeholder="************"
          onChange={({ target }) => setMainData(target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {passwordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          fullWidth
          loading={!!loadingApi.includes("post__reset_password")}
          type="submit"
          sx={{
            py: 1.3,
            fontSize: "1.2rem",
          }}
        >
          Restablecer Contraseña
        </LoadingButton>
      </Stack>
    </>
  );
}
