import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function DeleteRate({
  setOpenModaldelete,
  openModaldelete,
  deleteR,
}) {
  const handleCloseModaldelete = () => {
    setOpenModaldelete(null);
  };

  return (
    <>
      <Dialog
        open={!!openModaldelete}
        keepMounted
        onClose={handleCloseModaldelete}
      >
        <DialogTitle>{"Eliminar Tarifa"}</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: { md: 500 } }}>
            <DialogContentText id="deleterate">
              ¿Deseas eliminar esta tarifa?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModaldelete}>Cancelar</Button>
          <Button
            color="error"
            onClick={() => {
              handleCloseModaldelete();
              deleteR(openModaldelete);
            }}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
