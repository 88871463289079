import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function DeleteRegisterFile({
  openModaldeleteFile,
  setOpenModaldeleteFile,
  deleteregisterFile,
}) {
  return (
    <>
      <Dialog
        open={!!openModaldeleteFile}
        keepMounted
        onClose={() => {
          setOpenModaldeleteFile(false);
        }}
      >
        <DialogTitle>{"Eliminar documento"}</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 500 }}>
            <DialogContentText>
              ¿Deseas eliminar este documento?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModaldeleteFile(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            color="error"
            onClick={() => {
              setOpenModaldeleteFile(false);
              deleteregisterFile(
                openModaldeleteFile.id,
                openModaldeleteFile.index
              );
            }}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
