import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import SnackAlert from "../../components/SnackAlert";
import useSnack from "../../hooks/useSnack";
import GlobalHeader from "../../components/header";
import PrevIcon from "../../images/prev_verified.svg";
import Logo from "../../images/Logoazul.svg";
import { LoadingButton } from "@mui/lab";
import useApi from "../../hooks/useApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
function PrevLogin() {
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  return (
    <>
      <GlobalHeader />
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "calc(100vh - 80px)",
          padding: {
            xs: 4,
            sm: 0,
            lg: 4,
            xl: 0,
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            padding: "20px",
            animation: "entryX 0.5s ease forwards",
            position: "relative",
            boxShadow: "1px 1px 6px #e1e1e1f9",
            borderRadius: 4,
            width: "100%",
            maxWidth: {
              sm: "510px",
              xs: "100%",
            },
          }}
        >
          <LoadingButton
            LinkComponent={Link}
            to="/register"
            sx={{
              svg: { color: "#fff" },
              padding: 1,
              position: "absolute",
              top: "1rem",
              left: "1rem",
              margin: "0!important",
            }}
          >
            <ArrowBackIcon />
            {" Regresar"}
          </LoadingButton>
          <Stack spacing={2} alignItems="center" component="form">
            <img src={Logo} alt="" width={130} />
            <img src={PrevIcon} alt="" height={130} />
            <Typography variant="h6">Registro Exitoso</Typography>
            <Typography fontWeight={500} align="center">
              Por favor, verifica tu correo electrónico para iniciar sesión
              correctamente.
            </Typography>

            <Button
              LinkComponent={Link}
              to="/login"
              fullWidth
              sx={{
                py: 1.3,
                fontSize: "1.2rem",
              }}
            >
              Iniciar sesión
            </Button>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
export default PrevLogin;
