import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  Stack,
  IconButton,
  Alert,
  Autocomplete,
  TextField,
} from "@mui/material";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link, useNavigate } from "react-router-dom";
import dateToInput from "../../utils/DateToInput";
import ReactECharts from "echarts-for-react";
import ModalNewBillGeneral from "../incomeInfo/modalNewBillGenral";
import FormatearNumeroConSeparador from "../../utils/formatNumber";

export default function IncomeGeneral() {
  const [photo_profile, setPhoto_profile] = useState();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [filteredIncomes, setFilteredIncomes] = useState([]);
  const [openModalNewBill, setOpenModalNewBill] = useState(false);
  const [mappedOptions, setMappedOptions] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [centers, setCenters] = useState();
  const [clients, setClients] = useState();
  const [showAdditionalFields, setShowAdditionalFields] = useState(false); // permite nuevos campos para tarifa nueva (post)
  const [filters, setFilters] = useState({
    centerId: "",
    clientId: "",
    month: "",
    year: "",
  });
  const [amount, setAmount] = useState({
    Enero: 0,
    Febrero: 0,
    Marzo: 0,
    Abril: 0,
    Mayo: 0,
    Junio: 0,
    Julio: 0,
    Agosto: 0,
    Septiembre: 0,
    Octubre: 0,
    Noviembre: 0,
    Diciembre: 0,
  });
  const [status, setStatus] = useState({
    Enero: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Febrero: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Marzo: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Abril: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Mayo: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Junio: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Julio: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Agosto: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Septiembre: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Octubre: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Noviembre: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
    Diciembre: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
  });
  const [statusTotal, setStatusTotal] = useState({
    total: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
  });
  const [centersfiltred, setCentersfiltred] = useState();
  useEffect(() => {
    loadApi("center/centers_on", true, "get")
      .then((response) => {
        setCentersfiltred(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);

  // get de foto del profesional
  useEffect(() => {
    setOpenbackd(true);
    loadApi("profile", true, "get")
      .then((response) => {
        setPhoto_profile(response.data.photo_profile.url);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  // get de todos los ingresos
  useEffect(() => {
    setOpenbackd(true);
    loadApi("invoice/get_invoices", true, "get")
      .then((response) => {
        setIncomes(response.data.invoices);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  //--- get centros
  useEffect(() => {
    loadApi("center/centers", true, "get")
      .then((response) => {
        setCenters(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);
  useEffect(() => {
    loadApi("client/clients", true, "get")
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);

  //-----------------------
  const handleCloseModal = () => {
    setOpenModalNewBill(false);
    setMainDataNewBill({
      center_id: "",
      client_id: "",
      amount: "",
      date: "",
      status: "",
      method: "",
    });
    setMainDataNewRate({
      name: "",
      concurrence: "",
      price: "",
    });
    setShowAdditionalFields(false);
  };
  const [mainDataNewBill, setMainDataNewBill] = useState({
    center_id: "",
    client_id: "",
    name_rate: "",
    amount: "",
    date: "",
    status: "",
    method: "",
  });
  const [newBillError, setNewBillError] = useState({
    center_id: false,
    client_id: false,
    name_rate: false,
    amount: false,
    date: false,
    status: false,
    method: false,
  });
  const [mainDataNewRate, setMainDataNewRate] = useState({
    name: "",
    concurrence: "",
    price: "",
  });
  const [mainDataNewRateError, setMainDataNewRateError] = useState({
    name: false,
    concurrence: false,
    price: false,
  });
  const monthNames = {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  };
  /*   const handleFilter = () => {
    const filtered = incomes.filter((item) => {
      const matchesCenter = filters.centerId
        ? item?.center_id === parseInt(filters.centerId)
        : true;
      const matchesClient = filters.clientId
        ? item?.client_id === parseInt(filters?.clientId)
        : filters?.clientId === ""
        ? true
        : item?.client_id === null;
      const matchesMonth = filters?.month
        ? new Date(item.date).getMonth() + 1 === parseInt(filters.month)
        : true;

      return matchesCenter && matchesClient && matchesMonth;
    });

    const newAmounts = {
      Enero: 0,
      Febrero: 0,
      Marzo: 0,
      Abril: 0,
      Mayo: 0,
      Junio: 0,
      Julio: 0,
      Agosto: 0,
      Septiembre: 0,
      Octubre: 0,
      Noviembre: 0,
      Diciembre: 0,
    };
    const newStatus = {
      Enero: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Febrero: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Marzo: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Abril: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Mayo: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Junio: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Julio: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Agosto: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Septiembre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Octubre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Noviembre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Diciembre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
    };

    
    filtered.forEach((item) => {
      const date = new Date(item.date);
      const month = date.getMonth() + 1;
      const monthName = monthNames[month];

      newAmounts[monthName] += parseFloat(
        item.amount.replace(".", "").replace(",", ".")
      );
      newStatus[monthName][item.status].cantidad += 1; // Incrementar la cantidad de estado
      newStatus[monthName][item.status].valor += parseFloat(
        item.amount.replace(".", "").replace(",", ".")
      ); // Sumar el monto del estado
    });

    setAmount(newAmounts);
    setStatus(newStatus);
  }; */
  const handleFilter = () => {
    const filtered = incomes.filter((item) => {
      const matchesCenter = filters.centerId
        ? item?.center_id === parseInt(filters.centerId)
        : true;
      const matchesClient = filters.clientId
        ? item?.client_id === parseInt(filters?.clientId)
        : filters?.clientId === ""
        ? true
        : item?.client_id === null;
      const matchesYear = filters.year
        ? new Date(item.date).getFullYear() === parseInt(filters.year)
        : true;
      const matchesMonth = filters?.month
        ? new Date(item.date).getMonth() + 1 === parseInt(filters.month)
        : true;

      return matchesCenter && matchesClient && matchesYear && matchesMonth;
    });

    const newAmounts = {
      Enero: 0,
      Febrero: 0,
      Marzo: 0,
      Abril: 0,
      Mayo: 0,
      Junio: 0,
      Julio: 0,
      Agosto: 0,
      Septiembre: 0,
      Octubre: 0,
      Noviembre: 0,
      Diciembre: 0,
    };
    const newStatus = {
      Enero: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Febrero: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Marzo: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Abril: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Mayo: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Junio: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Julio: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Agosto: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Septiembre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Octubre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Noviembre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Diciembre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
    };

    filtered.forEach((item) => {
      const date = new Date(item.date);
      const month = date.getMonth() + 1;
      const monthName = monthNames[month];

      newAmounts[monthName] += parseFloat(
        item.amount.replace(".", "").replace(",", ".")
      );
      newStatus[monthName][item.status].cantidad += 1; // Incrementar la cantidad de estado
      newStatus[monthName][item.status].valor += parseFloat(
        item.amount.replace(".", "").replace(",", ".")
      ); // Sumar el monto del estado
    });

    setAmount(newAmounts);
    setStatus(newStatus);
  };
  useEffect(() => {
    handleFilter();
  }, [incomes, filters]);

  /*   const getOption = () => {
    const currentYear = new Date().getFullYear();

    // Filtrar los datos de `amount` para solo incluir los meses del año actual
    const filteredAmounts = Object.keys(amount).reduce((acc, month) => {
      const currentMonthData = incomes.filter((item) => {
        const itemDate = new Date(item.date);
        return (
          itemDate.getFullYear() === currentYear &&
          monthNames[itemDate.getMonth() + 1] === month
        );
      });

      if (currentMonthData.length > 0) {
        acc[month] = amount[month];
      } else {
        acc[month] = 0; // Asignar 0 si no hay datos para el mes actual
      }

      return acc;
    }, {});

    return {
      title: {
        text: "Ingresos por Mes",
        left: "center", // Centrar el título horizontalmente
        textStyle: {
          color: "#333",
          fontSize: 18,
        },
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        data: [
          "ENE",
          "FEB",
          "MAR",
          "ABR",
          "MAY",
          "JUN",
          "JUL",
          "AGO",
          "SEP",
          "OCT",
          "NOV",
          "DIC",
        ],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Ingreso",
          type: "bar",
          data: Object.keys(filteredAmounts)?.map(
            (month) => filteredAmounts[month]
          ),
          itemStyle: {
            color: "#002B44",
          },
        },
      ],
    };
  }; */
  const getOption = () => {
    const selectedYear = filters.year ? parseInt(filters.year) : null;

    // Filtrar los datos de `amount` para solo incluir los meses del año seleccionado
    const filteredAmounts = Object.keys(amount).reduce((acc, month) => {
      const currentMonthData = incomes.filter((item) => {
        const itemDate = new Date(item.date);
        const itemYear = itemDate.getFullYear();
        const itemMonthName = monthNames[itemDate.getMonth() + 1];

        return (
          (selectedYear ? itemYear === selectedYear : true) &&
          itemMonthName === month
        );
      });

      if (currentMonthData.length > 0) {
        acc[month] = amount[month];
      } else {
        acc[month] = 0; // Asignar 0 si no hay datos para el mes actual
      }

      return acc;
    }, {});

    return {
      title: {
        text: "Ingresos por Mes",
        left: "center", // Centrar el título horizontalmente
        textStyle: {
          color: "#333",
          fontSize: 18,
        },
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        data: [
          "ENE",
          "FEB",
          "MAR",
          "ABR",
          "MAY",
          "JUN",
          "JUL",
          "AGO",
          "SEP",
          "OCT",
          "NOV",
          "DIC",
        ],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Ingreso",
          type: "bar",
          data: Object.keys(filteredAmounts)?.map(
            (month) => filteredAmounts[month]
          ),
          itemStyle: {
            color: "#002B44",
          },
        },
      ],
    };
  };

  const calculateStatusTotal = () => {
    const newStatusTotal = {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    };

    Object.values(status).forEach((monthStatus) => {
      Object.keys(monthStatus).forEach((key) => {
        newStatusTotal[key].cantidad += monthStatus[key].cantidad;
        newStatusTotal[key].valor += monthStatus[key].valor;
      });
    });

    setStatusTotal({ total: newStatusTotal });
  };

  useEffect(() => {
    calculateStatusTotal();
  }, [status]);

  // Datos para el gráfico Doughnut
  const colors = {
    Pendiente: "#fd9e3a",
    Cobrado: "#4d9a51",
    Atrasado: "#f83b58",
  };

  const data = {
    legendData: ["Pendiente", "Cobrado", "Atrasado"],
    seriesData: [
      {
        name: "Pendiente",
        value: statusTotal.total.Pendiente.cantidad,
        valor: statusTotal.total.Pendiente.valor,
      },
      {
        name: "Cobrado",
        value: statusTotal.total.Cobrado.cantidad,
        valor: statusTotal.total.Cobrado.valor,
      },
      {
        name: "Atrasado",
        value: statusTotal.total.Atrasado.cantidad,
        valor: statusTotal.total.Atrasado.valor,
      },
    ],
  };
  /* const option = {
    title: {
      text: "Estado de Ingresos",
      x: "center",
      textStyle: {
        fontSize: 18,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `${params.seriesName} <br/>${params.name} : ${params.value} (${
          params.percent
        }%)<br/>Valor: ${FormatearNumeroConSeparador(params.data.valor)}`;
      },
    },
    legend: {
      orient: "horizontal",
      bottom: 10,
      left: "center",
      data: data.legendData,
    },
    series: [
      {
        name: "Estado",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data.seriesData.map((item) => ({
          name: item.name,
          value: item.value,
          itemStyle: {
            color: colors[item.name],
          },
          valor: item.valor, // Añadir el valor para usar en el tooltip
        })),
      },
    ],
  }; */
  const option = {
    title: {
      text: "Estado de Ingresos",
      x: "center",
      textStyle: {
        fontSize: 18,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `${params.seriesName} <br/>${params.name} : ${params.value} (${
          params.percent
        }%)<br/>Valor: ${FormatearNumeroConSeparador(params.data.valor)}`;
      },
    },
    legend: {
      orient: "horizontal",
      bottom: 10,
      left: "center",
      data: data.legendData,
    },
    series: [
      {
        name: "Estado",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data.seriesData.map((item) => ({
          name: item.name,
          value: item.value,
          itemStyle: {
            color: colors[item.name],
          },
          valor: item.valor, // Añadir el valor para usar en el tooltip
        })),
      },
    ],
  };

  useEffect(() => {
    const options = (clients || []).map((item) => ({
      label: `${item.name} ${item.lastname}`,
      value: item.id,
    }));
    options.push({ label: "sin paciente", value: null });
    options.unshift({ label: "Todos los pacientes", value: "" });
    setMappedOptions(options);
  }, [clients]);
  const SubmitmodalBill = async (e) => {
    e.preventDefault();

    if (showAdditionalFields === false) {
      if (!ValidateModalNewBill(mainDataNewBill, setNewBillError)) return;
      setOpenModalNewBill(false);
      setOpenbackd(true);

      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setIncomes((adata) => {
            const newInvoices = [...adata, response.data.response.invoices];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModal();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    } else {
      if (!ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError))
        return;
      if (!ValidateModalNewBill(mainDataNewBill, setNewBillError)) return;
      setOpenModalNewBill(false);
      setOpenbackd(true);

      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setIncomes((adata) => {
            const newInvoices = [...adata, response.data.response.invoices];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModal();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {});

      loadApi(`rate/${mainDataNewBill.center_id}`, true, "post", {
        ...mainDataNewRate,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    }
  };
  const [uniqueYears, setUniqueYears] = useState([]);
  const getUniqueYearsFromIncomes = (incomes) => {
    const yearsSet = new Set();
    incomes.forEach((income) => {
      const year = new Date(income.date).getFullYear();
      yearsSet.add(year);
    });
    return Array.from(yearsSet);
  };
  useEffect(() => {
    if (incomes.length > 0) {
      const years = getUniqueYearsFromIncomes(incomes);
      setUniqueYears(years);
    }
  }, [incomes]);
  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <Grid container direction="row">
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Typography variant="h5" marginTop={3}>
              Ingresos Generales
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Stack
              py={2}
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
              sx={{
                justifyContent: { xs: "center", sm: "flex-end" },
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  display: { xs: "inherit", sm: "none" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },

                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white",
                  },
                }}
                onClick={() => {
                  navigate("/income");
                }}
              >
                <Typography color="black">General</Typography>
              </Button>
              <Button
                variant="text"
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },

                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white",
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80%", // Full width on extra small screens
                      sm: "auto", // Auto width on medium screens and up
                    },
                    borderBottom: "3px solid",
                    alignItems: "center",

                    pb: 1,
                    svg: {
                      color: "#fff!important",
                    },
                  }}
                  onClick={() => {
                    navigate("/income");
                  }}
                >
                  <Typography color="black">General</Typography>
                </Box>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  display: { xs: "inherit", sm: "none" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },

                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white",
                  },
                }}
                onClick={() => {
                  navigate("/income/info");
                }}
              >
                <Typography color="black">Detalles</Typography>
              </Button>
              <Button
                variant="text"
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white", // Remove background color change
                    //opacity: 0.8, // Apply opacity change
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80%", // Full width on extra small screens
                      md: "auto", // Auto width on medium screens and up
                    },
                    borderBottom: "3px solid",
                    alignItems: "center",

                    pb: 1,
                    svg: {
                      color: "#fff!important",
                    },
                  }}
                  onClick={() => {
                    navigate("/income/info");
                  }}
                >
                  <Typography color="black">Detalles</Typography>
                </Box>
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Grid container direction="row" marginTop={2}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Centro:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters.centerId}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      centerId: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los centros...</MenuItem>
                  {centers &&
                    (centers || []).map((row, index) => (
                      // recorrer centros row.id sera value y se mostrara row.name
                      <MenuItem value={row?.id}>{row?.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Paciente:</Typography>
              <Autocomplete
                size="small"
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                options={mappedOptions}
                getOptionLabel={(option) => option?.label}
                value={
                  mappedOptions?.find(
                    (option) => option.value === filters.clientId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    clientId: newValue ? newValue.value : "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ borderRadius: 100 }}
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Mes:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters?.month}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      month: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los meses</MenuItem>
                  <MenuItem value="01">Enero</MenuItem>
                  <MenuItem value="02">Febrero</MenuItem>
                  <MenuItem value="03">Marzo</MenuItem>
                  <MenuItem value="04">Abril</MenuItem>
                  <MenuItem value="05">Mayo</MenuItem>
                  <MenuItem value="06">Junio</MenuItem>
                  <MenuItem value="07">Julio</MenuItem>
                  <MenuItem value="08">Agosto</MenuItem>
                  <MenuItem value="09">Septiembre</MenuItem>
                  <MenuItem value="10">Octubre</MenuItem>
                  <MenuItem value="11">Noviembre</MenuItem>
                  <MenuItem value="12">Diciembre</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Año:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Autocomplete
                  size="small"
                  value={filters.year}
                  onChange={(event, newValue) =>
                    setFilters({ ...filters, year: newValue })
                  }
                  options={["", ...uniqueYears]} // Incluye opción para "Todos los años"
                  getOptionLabel={(option) =>
                    option === "" ? "Todos los años" : option.toString()
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            sx={{ mt: { xs: 2, md: 0 }, px: 2 }}
          >
            <Button
              fullWidth
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",

                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
              }}
              onClick={() => {
                setFilters({
                  centerId: "",
                  clientId: "",
                  month: "",
                  year: "",
                });
              }}
            >
              Limpiar Filtros
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ m: 2 }} />
        <Stack>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setOpenModalNewBill(true);
            }}
            sx={{
              bgcolor: "secondary.main",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
            <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
              Nuevo Ingreso
            </Typography>
          </Button>
        </Stack>
        <Divider sx={{ m: 2 }} />
        <Box
          sx={{
            overflowX: { xs: "scroll!important", sm: "hidden!important" },
            width: "100%",
            "&::-webkit-scrollbar": {
              height: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
            mb: 2,
          }}
        >
          <Stack minWidth="600px" marginTop={3}>
            <ReactECharts
              option={getOption()}
              style={{ height: "400px", width: "100%" }}
            />
          </Stack>
        </Box>
        <Stack width="100%">
          <ReactECharts option={option} style={{ height: "400px" }} />
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalNewBillGeneral
        openModalNewBill={openModalNewBill}
        handleCloseModal={handleCloseModal}
        centers={centersfiltred}
        mainDataNewBill={mainDataNewBill}
        setMainDataNewBill={setMainDataNewBill}
        SubmitmodalBill={SubmitmodalBill}
        loadApi={loadApi}
        newBillError={newBillError}
        showAdditionalFields={showAdditionalFields}
        setShowAdditionalFields={setShowAdditionalFields}
        mainDataNewRate={mainDataNewRate}
        setMainDataNewRate={setMainDataNewRate}
        mainDataNewRateError={mainDataNewRateError}
        clients={clients}
      />
    </>
  );
}
function ValidateModalNewBill(mainDataNewBill, setNewBillError) {
  var final = false;
  const newAdata = {
    center_id: !mainDataNewBill.center_id,
    name_rate: !mainDataNewBill.name_rate,
    amount: !mainDataNewBill.amount,
    date: !mainDataNewBill.date,
    status: !mainDataNewBill.status,
  };

  setNewBillError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
function ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError) {
  var final = false;
  const newAdata = {
    name: !mainDataNewRate.name,
    concurrence: !mainDataNewRate.concurrence,
    price: !mainDataNewRate.price,
  };

  setMainDataNewRateError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
