import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SnackAlert from "../../components/SnackAlert";
import GlobalHeader from "../../components/header";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import ModalCommunications from "./modalCommunications";
import ModalClient from "./modalclient";

import Modaltreatments from "./modaltreatments";
import capitalizarCadena from "../../utils/Cadena";

import DateCusmton from "../../utils/DateCusmton";
export default function Clients() {
  const navigate = useNavigate();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { loadApi } = useApi();
  const [openbackd, setOpenbackd] = useState(false);
  const [photo_profile, setPhoto_profile] = useState();
  const initialMainData = {
    center_id: "",
    name: "",
    lastname: "",
    national_id: "",
    email: "",
    phone: "",
    address: "",
    note: "",
  };
  const [mainData, setMainData] = useState(initialMainData);
  const [mainDataError, setMainDataError] = useState({
    center_id: false,
    name: false,
    lastname: false,
    national_id: false,
    email: false,
    phone: false,
    address: false,
    note: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [openModalCom, setOpenModalCom] = useState(false);
  const [openModalTret, setOpenModalTret] = useState(false);
  const [centersfiltred, setCentersfiltred] = useState();
  useEffect(() => {
    loadApi("center/centers_on", true, "get")
      .then((response) => {
        setCentersfiltred(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);
  const [searchText, setSearchText] = useState({
    searchText: "",
  });

  const [mainDataModalComError, setMainDataModalComError] = useState({
    channel: false,
    text: false,
  });
  const [mainDataModalCom, setMainDataModalCom] = useState({
    channel: "",
    text: "",
  });
  useEffect(() => {
    setMainData(initialMainData);
  }, [openModal]);

  const [clients, setClients] = useState([]);
  useEffect(() => {
    loadApi("client/clients", true, "get")
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);
  useEffect(() => {
    setOpenbackd(true);
    loadApi("profile", true, "get")
      .then((response) => {
        setPhoto_profile(response.data.photo_profile.url);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setMainDataError({
      center_id: false,
      name: false,
      lastname: false,
      national_id: false,
      email: false,
      phone: false,
      address: false,
      note: false,
    });
  };
  const handleCloseModalTret = () => {
    setOpenModalTret(false);
  };

  const handleCloseModalCom = () => {
    setOpenModalCom(false);
    setMainDataModalCom({
      channel: "",
      text: "",
    });
  };
  const Submit = async (e) => {
    e.preventDefault();

    if (!Validate(mainData, setMainDataError)) return;
    handleCloseModal();
    setOpenbackd(true);
    loadApi("client", true, "post", {
      ...mainData,
    })
      .then((response) => {
        showSnack(response.data.message, "success", 2000);

        setClients((adata) => [...adata, response.data.Client]);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const filteredClients = clients.filter((client) => {
    const searchTextLower = searchText.searchText.toLowerCase();
    const clientNameLower = client?.name.toLowerCase();
    const centerNameLower = client.Center?.name.toLowerCase();
    const clientLastNameLower = client?.lastname.toLowerCase();

    return (
      clientNameLower.includes(searchTextLower) ||
      centerNameLower.includes(searchTextLower) ||
      clientLastNameLower.includes(searchTextLower)
    );
  });
  const SubmitModalCommunications = () => {
    if (!ValidateModalCom(mainDataModalCom, setMainDataModalComError)) return;
    handleCloseModalCom();
    if (mainDataModalCom.channel === "Email") {
      setOpenbackd(true);
      loadApi(
        `externalChats/sendEmail/${openModalCom.id}/${openModalCom.center_id}`,
        true,
        "post",
        {
          ...mainDataModalCom,
        }
      )
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
          setMainDataModalComError({
            channel: false,
            text: false,
          });
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    } else {
      showSnack("El servicio aún no está habilitado", "error");
    }
  };
  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container
        maxWidth="lg"
        sx={{ alignItems: "center", background: "#FFFFFF", p: 2 }}
      >
        <Grid container direction="row" sx={{ mb: 2 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 600, color: "black", mt: 3 }}
            >
              Pacientes
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box display="flex" justifyContent="flex-end" marginY={2}>
              <TextField
                id="outlined-basic"
                // Eliminar el label
                label=""
                placeholder="Buscar"
                variant="outlined"
                value={searchText.searchText}
                onChange={(event) =>
                  setSearchText((adata) => ({
                    ...adata,
                    searchText: event.target.value,
                  }))
                }
                InputProps={{
                  sx: {
                    borderRadius: 3,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 2 }} />
        <Stack>
          <Button
            variant="contained"
            disableElevation
            onClick={handleClickOpenModal}
            sx={{
              mb: 2,
              bgcolor: "secondary.main",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
            <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
              Nuevo Paciente
            </Typography>
          </Button>
        </Stack>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Paciente </TableCell>
                <TableCell align="center"> Ultima Consulta</TableCell>
                <TableCell align="center">Cantidad de Consultas</TableCell>
                <TableCell align="center">Facturas Pendientes</TableCell>
                <TableCell align="center">Consultas Agendadas</TableCell>
                <TableCell align="center">Centro</TableCell>
                <TableCell align="center">Tratamiento Ralizado</TableCell>
                <TableCell align="center">Enviar Mensaje</TableCell>
                <TableCell align="center">Detalle</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClients &&
                (filteredClients || []).map((row, index) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" scope="row">
                      {capitalizarCadena(row.name + " " + row.lastname)}
                    </TableCell>
                    <TableCell align="center">
                      {row.Calendars.lastConsultationDate !== null
                        ? DateCusmton(row.Calendars.lastConsultationDate)
                        : "Sin consultas anteriores"}
                    </TableCell>
                    <TableCell align="center">
                      {row.Calendars.totalConsultations !== 0
                        ? row.Calendars.totalConsultations
                        : "Sin consultas "}
                    </TableCell>
                    <TableCell align="center">
                      {row.pendingInvoicesCount !== 0
                        ? row.pendingInvoicesCount
                        : "Sin facturas pendientes "}
                    </TableCell>
                    <TableCell align="center">
                      {row.Calendars.upcomingConsultations !== 0
                        ? row.Calendars.upcomingConsultations
                        : "Sin consultas agendadas "}
                    </TableCell>
                    <TableCell align="center">
                      {capitalizarCadena(
                        !row?.Center?.name ? "Sin Centro" : row?.Center?.name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() =>
                          setOpenModalTret({
                            invoices: row.invoices,
                          })
                        }
                      >
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          setOpenModalCom({
                            id: row.id,
                            hasEmail: row?.hasEmail,
                            center_id: row?.Center?.id,
                          });
                        }}
                      >
                        <ChatIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        variant="outlined"
                        fullWidth
                        onClick={() => navigate(`/client/${row.id}`)}
                      >
                        <ModeEditOutlineOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {filteredClients?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">Aún no tiene pacientes.</Alert>
          </Stack>
        )}
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <ModalClient
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        mainData={mainData}
        setMainData={setMainData}
        centers={centersfiltred}
        Submit={Submit}
        mainDataError={mainDataError}
      />
      <ModalCommunications
        openModalCom={openModalCom}
        setMainDataModalCom={setMainDataModalCom}
        mainDataModalCom={mainDataModalCom}
        handleCloseModalCom={handleCloseModalCom}
        SubmitModalCommunications={SubmitModalCommunications}
        mainDataModalComError={mainDataModalComError}
        setMainDataModalComError={setMainDataModalComError}
      />
      <Modaltreatments
        openModalTret={openModalTret}
        handleCloseModalTret={handleCloseModalTret}
      />
    </>
  );
}

function Validate(mainData, setMainDataError) {
  var final = false;
  const newAdata = {
    center_id: !mainData.center_id,
    national_id: !mainData.national_id,
    name: !mainData.name,
    lastname: !mainData.lastname,
  };

  setMainDataError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
function ValidateModalCom(mainDataModalCom, setMainDataModalComError) {
  var final = false;
  const newAdata = {
    channel: !mainDataModalCom.channel,
    text: !mainDataModalCom.text,
  };

  setMainDataModalComError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
