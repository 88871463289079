"use client";
import { Stack } from "@mui/material";
import { useState } from "react";
import SnackAlert from "../../components/SnackAlert";
import useSnack from "../../hooks/useSnack";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import GlobalHeader from "../../components/header";

function MuiContent() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  return (
    <>
      <GlobalHeader />
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "calc(100vh - 80px)",
          padding: {
            xs: 4,
            sm: 0,
            lg: 4,
            xl: 0,
          },
        }}
      >
        {step === 1 && (
          <Step1
            setStep={setStep}
            showSnack={showSnack}
            email={email}
            setEmail={setEmail}
          />
        )}
        {step === 2 && (
          <Step2
            email={email}
            setStep={setStep}
            showSnack={showSnack}
            setToken={setToken}
          />
        )}
        {step === 3 && (
          <Step3 setStep={setStep} token={token} showSnack={showSnack} />
        )}
        {step === 4 && <Step4 />}
      </Stack>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}

export default MuiContent;
