import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export default function BackClients() {
  return (
    <>
      <LoadingButton
        LinkComponent={Link}
        to="/clients"
        sx={{
          svg: { color: "#fff" },
          padding: 1,

          margin: "0!important",
        }}
      >
        <ArrowBackIcon />
        {"Atrás"}
      </LoadingButton>
    </>
  );
}
