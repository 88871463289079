import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function DeleteRegister({
  openModaldelete,
  handleCloseModaldelete,
  deleteregister,
}) {
  return (
    <>
      <Dialog
        open={!!openModaldelete}
        keepMounted
        onClose={handleCloseModaldelete}
      >
        <DialogTitle>{"Eliminar Registro"}</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 500 }}>
            <DialogContentText id="deletecenter">
              ¿Deseas eliminar este registro?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModaldelete}>Cancelar</Button>
          <Button
            color="error"
            onClick={() => {
              handleCloseModaldelete();
              deleteregister(openModaldelete);
            }}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
