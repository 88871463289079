import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";

import { useEffect, useState } from "react";
import capitalizarCadena from "../../utils/Cadena";
import dateToInput from "../../utils/DateToInput";

import dayjs from "dayjs";
export default function ModalupdateAppo({
  openModalInfoAppo,
  handleCloseModalInfoAppo,
  setMainData,
  mainData,
  centers,

  SubmitmodalUpdateAppo,

  idAppointments,
  Error,
}) {
  useEffect(() => {
    setMainData({
      center_id: openModalInfoAppo.center_id,
      client_id: openModalInfoAppo.client_id,
      date: openModalInfoAppo.date,
      startAt: openModalInfoAppo.start,
      endAt: openModalInfoAppo.end,
      phone: openModalInfoAppo.phone,
      note: openModalInfoAppo.note,
    });
  }, [openModalInfoAppo]);
  const generateHoursOptions = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, "0");
      hours.push({ label: `${hour}:00`, value: `${hour}:00` });
      hours.push({ label: `${hour}:30`, value: `${hour}:30` });
    }
    return hours;
  };
  const hoursOptions = generateHoursOptions();

  return (
    <>
      <Dialog
        open={!!openModalInfoAppo}
        onClose={handleCloseModalInfoAppo}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden" }}
      >
        <DialogTitle>Detalle Cita</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box sx={{ minWidth: { xs: "auto", md: 500 }, p: 1 }}>
            <Grid container direction="row" spacing={2} marginBottom={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Centro</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    value={mainData?.center_id || openModalInfoAppo.center_id}
                    onChange={({ target }) =>
                      setMainData((adata) => ({
                        ...adata,
                        center_id: target.value,
                      }))
                    }
                    label="Centro"
                    required
                    error={Error.center_id}
                  >
                    {centers &&
                      (centers || []).map((row, index) => (
                        // recorrer centros row.id sera value y se mostrara row.name
                        <MenuItem value={row?.id}>{row?.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  disabled
                  label="Paciente"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  id="Name" // ID personalizado
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={openModalInfoAppo.fullname}
                  required
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} marginBottom={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Fecha"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  variant="outlined"
                  fullWidth
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      date: target.value,
                    }))
                  }
                  value={dateToInput(mainData?.date)}
                  required
                  error={Error.date}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Celular"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  id="Name" // ID personalizado
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={mainData?.phone}
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      phone: target.value,
                    }))
                  }
                  required
                  error={Error.phone}
                  onKeyDown={(event) => {
                    // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} marginBottom={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {/* <TextField
                  type="time"
                  fullWidth
                  value={mainData?.startAt}
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  label="Hora Inicio"
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      startAt: target.value,
                    }))
                  }
                  error={Error.startAt}
                /> */}
                <FormControl fullWidth required>
                  <InputLabel id="start-time-label">Hora Inicio</InputLabel>
                  <Select
                    sx={{ borderRadius: 2 }}
                    labelId="start-time-label"
                    value={mainData?.startAt || openModalInfoAppo.start}
                    onChange={({ target }) =>
                      setMainData((adata) => ({
                        ...adata,
                        startAt: target.value,
                      }))
                    }
                    label="Hora Inicio"
                    error={Error.startAt}
                  >
                    {hoursOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {/* <TextField
                  type="time"
                  fullWidth
                  value={mainData?.endAt}
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  label="Hora Fin"
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      endAt: target.value,
                    }))
                  }
                  error={Error.endAt}
                /> */}
                <FormControl fullWidth required>
                  <InputLabel id="start-time-label">Hora Fin</InputLabel>
                  <Select
                    labelId="start-time-label"
                    value={mainData?.endAt || openModalInfoAppo.end}
                    onChange={({ target }) =>
                      setMainData((adata) => ({
                        ...adata,
                        endAt: target.value,
                      }))
                    }
                    label="Hora Inicio"
                    error={Error.endAt}
                  >
                    {hoursOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Stack>
              <TextField
                id="outlined-textarea"
                label="Nota"
                InputProps={{
                  sx: { borderRadius: 2 },
                }}
                placeholder="Nota"
                multiline
                rows={2}
                fullWidth
                value={mainData?.note}
                onChange={({ target }) =>
                  setMainData((adata) => ({
                    ...adata,
                    note: target.value,
                  }))
                }
                error={Error.note}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            px: 2,
            mb: 1,
          }}
        >
          <Button
            onClick={handleCloseModalInfoAppo}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={SubmitmodalUpdateAppo}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
