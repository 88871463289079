import { Box, Stack, TextField, Typography } from "@mui/material";
import MailIcon from "../../images/mail.svg";
import Logo from "../../images/Logoazul.svg";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import useApi from "../../hooks/useApi";

export default function RecoveryPWD1({ email, setStep, showSnack, setToken }) {
  const [mainData, setMainData] = useState("");
  const { loadApi, loadingApi } = useApi();
  const Submit = async (e) => {
    e.preventDefault();
    loadApi("reset_password/sign", false, "post", {
      token: mainData,
      username: email,
    })
      .then((response) => {
        if (response.data?.token) {
          setToken(response.data?.token);
          setStep(3);
        } else {
          showSnack("Error al procesar la solicitud.");
        }
      })
      .catch((e) => {
        showSnack(e.message);
      });
  };
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        padding: "20px",
        animation: "entryX 0.5s ease forwards",
        position: "relative",
        boxShadow: "1px 1px 6px #e1e1e1f9",
        borderRadius: 4,
        width: "100%",
        maxWidth: {
          sm: "510px",
          xs: "100%",
        },
      }}
    >
      <LoadingButton
        onClick={() => setStep(1)}
        sx={{
          svg: { color: "#fff" },
          padding: 1,
          position: "absolute",
          top: "1rem",
          left: "1rem",
          margin: "0!important",
        }}
      >
        <ArrowBackIcon />
      </LoadingButton>
      <Stack spacing={2} alignItems="center" component="form" onSubmit={Submit}>
        <img src={Logo} alt="" width={130} style={{ marginBottom: "1.5rem" }} />
        <img src={MailIcon} alt="" height={80} style={{ margin: "1.2rem 0" }} />
        <Typography variant="h6">Ingresa el código recibido</Typography>
        <Typography fontWeight={500} align="center">
          Revise tu bandeja de correo e ingresa el último código de recuperación
          recibido. Por favor revise en <b>Spam</b>.
        </Typography>
        <TextField
          type="number"
          onChange={({ target }) => setMainData(target.value)}
          value={mainData}
          placeholder="XXXXXX"
          fullWidth
        ></TextField>
        <LoadingButton
          fullWidth
          loading={!!loadingApi.includes("post__reset_password/sign")}
          type="submit"
          sx={{
            py: 1.3,
            fontSize: "1.2rem",
          }}
        >
          Continuar
        </LoadingButton>
      </Stack>
    </Box>
  );
}
