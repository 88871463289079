import { Box, Button, InputAdornment, TextField } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function Centros({
  showSnack,
  setRegistros,
  setOpenbackd,
  openModal,
  handleCloseModal,
  setMainData2,
  mainData2,
  errorName,
  Submitmodal,
}) {
  /*   const { loadApi, loadingApi } = useApi();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [mainData2, setMainData2] = useState({
    name: "",
  });
  const [errorName, setErrorName] = useState({
    errorN: false,
    Message: "",
  });

  //Guardar Modal
  const Submitmodal = async (e) => {
    e.preventDefault();
    let errname;
    if (mainData2.name.length < 1) {
      setErrorName({
        errorN: true,
        Message: "Error nombre del centro está vacio",
      });
      errname = true;
    } else {
      setErrorName({
        errorN: false,
        Message: "",
      });
      errname = false;
    }
    if (errname === false) {
      handleCloseModal();
      setOpenbackd(true);
      loadApi("center", true, "post", {
        ...mainData2,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
          setRegistros((adata) => [...adata, response.data.newCenter]);
          setTimeout(() => {
            setMainData2({ name: "" });
          }, 1000);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    }
  }; */

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden" }}
      >
        <DialogTitle>Agregar nuevo centro</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box sx={{ width: { md: 500 }, p: 1 }}>
            <TextField
              label="Nombre del centro"
              InputProps={{
                sx: { borderRadius: 2 },
                endAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              id="name" // ID personalizado
              onChange={({ target }) =>
                setMainData2((adata) => ({ ...adata, name: target.value }))
              }
              value={mainData2.name}
              helperText={errorName.Message}
              error={errorName.errorN}
              required
              type="text"
              variant="outlined"
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={Submitmodal}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
