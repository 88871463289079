import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

function Redirect() {
  const navigate = useNavigate();
  const { loggedAuth } = useAuth();

  useEffect(() => {
    if (!loggedAuth) {
      navigate("/login");
    } else {
      navigate("/");
    }
  }, []);

  return <></>;
}

export default Redirect;
