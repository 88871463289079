import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DateCusmton from "../../utils/DateCusmton";
export default function Modaltreatments({
  openModalTret,
  handleCloseModalTret,
}) {
  return (
    <>
      <Dialog
        open={!!openModalTret}
        // onClose={handleCloseModalTret}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden" }}
      >
        <DialogTitle>Tratamientos realizados </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box sx={{ minWidth: { md: 550 } }}>
            <Divider />
            {openModalTret.invoices?.length < 1 && (
              <Stack mt={2}>
                <Alert severity="warning">
                  El paciente no tiene tratamientos.
                </Alert>
              </Stack>
            )}
            {openModalTret.invoices?.length > 0 && (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Fecha </TableCell>
                      <TableCell align="center">Tratamiento</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {openModalTret?.invoices &&
                      (openModalTret.invoices || []).map((row, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {DateCusmton(row?.date)}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {row?.name_rate}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={handleCloseModalTret}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
