import { Box, Stack, TextField, Typography } from "@mui/material";
import ResetPwdIcon from "../../images/forgot.svg";
import Logo from "../../images/Logoazul.svg";
import { LoadingButton } from "@mui/lab";
import useApi from "../../hooks/useApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";

export default function RecoveryPWD1({ showSnack, setStep, email, setEmail }) {
  const { loadApi, loadingApi } = useApi();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const Submit = async (e) => {
    e.preventDefault();
    loadApi("reset_password/sendmail", false, "post", { username: email })
      .then(() => {
        setStep(2);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        padding: "20px",
        animation: "entryX 0.5s ease forwards",
        position: "relative",
        boxShadow: "1px 1px 6px #e1e1e1f9",
        borderRadius: 4,
        width: "100%",
        maxWidth: {
          sm: "510px",
          xs: "100%",
        },
      }}
    >
      <LoadingButton
        LinkComponent={Link}
        to="/login"
        sx={{
          svg: { color: "#fff" },
          padding: 1,
          position: "absolute",
          top: "1rem",
          left: "1rem",
          margin: "0!important",
        }}
      >
        <ArrowBackIcon />
        {!isXS && " Ingresar"}
      </LoadingButton>
      <Stack spacing={2} alignItems="center" component="form" onSubmit={Submit}>
        <img src={Logo} alt="" width={130} />
        <img src={ResetPwdIcon} alt="" height={130} />
        <Typography variant="h6">Restablecer Contraseña</Typography>
        <Typography fontWeight={500} align="center">
          Ingresa tu correo électronico y si coincide y existe en nuestro
          sistema recibirás un código.
        </Typography>
        <TextField
          type="email"
          onChange={({ target }) => setEmail(target.value)}
          value={email}
          placeholder="ejemplo@mail.com"
          fullWidth
        ></TextField>
        <LoadingButton
          loading={!!loadingApi.includes("post__reset_password/sendmail")}
          fullWidth
          type="submit"
          sx={{
            py: 1.3,
            fontSize: "1.2rem",
          }}
        >
          Continuar
        </LoadingButton>
      </Stack>
    </Box>
  );
}
