export default function FormatearNumeroConSeparador(numero) {
    // Verificar si el número es null o undefined
    if (numero == null) {
      return ""; // Retornar una cadena vacía en estos casos
    }

    // Convertir el número a string y eliminar cualquier separador de miles existente
    let numeroString = numero.toString().replace(/\./g, "");

    // Si el número es '0', retornar '0' sin procesar
    if (numeroString === "0") {
      return numeroString;
    }

    // Invertir el string para facilitar la inserción de separadores
    let numeroInvertido = numeroString.split("").reverse().join("");

    let numeroFormateado = "";

    // Insertar separadores de miles cada tres caracteres
    for (let i = 0; i < numeroInvertido.length; i++) {
      if (i > 0 && i % 3 === 0) {
        numeroFormateado += ".";
      }
      numeroFormateado += numeroInvertido[i];
    }

    // Revertir nuevamente el string para obtener el formato correcto
    return numeroFormateado.split("").reverse().join("");
  }