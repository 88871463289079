import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  Stack,
  IconButton,
  Alert,
  Chip,
  TextField,
  Autocomplete,
} from "@mui/material";
import GlobalHeader from "../../components/header";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link, useNavigate } from "react-router-dom";
import dateToInput from "../../utils/DateToInput";
import FormatearNumeroConSeparador from "../../utils/formatNumber";
import capitalizarCadena from "../../utils/Cadena";
import Papa from "papaparse";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ModalNewBillGeneral from "./modalNewBillGenral";
import DateCusmton from "../../utils/DateCusmton";
import { write, utils } from "xlsx";
import ModalUpdateBillGeneral from "./modalUpdateBillGeneral";
import ModalDeleteBillGeneral from "./deletebillgeneral";
export default function IncomeInfo() {
  const [photo_profile, setPhoto_profile] = useState();
  const [openModalNewBill, setOpenModalNewBill] = useState(false);
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false); // permite nuevos campos para tarifa nueva (post)
  const [openModalUpdateBill, setOpenModalUpdateBill] = useState(false); // abrir modal de actualizar factura
  const [invoices, setInvoices] = useState([]);
  const [centers, setCenters] = useState();
  const [clients, setClients] = useState();
  const [idupdate, setIdupdate] = useState();
  const [mappedOptions, setMappedOptions] = useState([]);
  const [openModaldelete, setOpenModaldelete] = useState(false);
  const [mainDataFilters, setMainDataFilters] = useState({
    name_center: "",
    month: "",
    clientId: "",
    year: "",
  });
  // get de foto del profesional
  useEffect(() => {
    setOpenbackd(true);
    loadApi("profile", true, "get")
      .then((response) => {
        setPhoto_profile(response.data.photo_profile.url);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  useEffect(() => {
    setOpenbackd(true);
    loadApi("client/clients", true, "get")
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  useEffect(() => {
    setOpenbackd(true);
    loadApi("invoice/get_invoices", true, "get")
      .then((response) => {
        setInvoices(response.data.invoices);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  useEffect(() => {
    loadApi("center/centers", true, "get")
      .then((response) => {
        setCenters(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);
  const [centersfiltred, setCentersfiltred] = useState();
  useEffect(() => {
    loadApi("center/centers_on", true, "get")
      .then((response) => {
        setCentersfiltred(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);

  const getUniqueYears = (invoices) => {
    const years = invoices.map((invoice) =>
      new Date(invoice.date).getUTCFullYear()
    );
    return Array.from(new Set(years)); // Eliminar duplicados
  };

  const uniqueYears = getUniqueYears(invoices);

  /* const [filteredData2, setFilteredData2] = useState([]);
  const filteredData = invoices.filter((item) => {
    const formattedDate = dateToInput(item?.date);
    const itemDate = new Date(formattedDate);

    // Extraer el mes y el año de la fecha del item
    const itemMonth = itemDate.getUTCMonth() + 1; // Sumar 1 porque los meses van de 0 a 11
    const itemYear = itemDate.getUTCFullYear();

    // Convertir el mes seleccionado en número (en caso de que sea una cadena)
    const selectedMonth = Number(mainDataFilters.month);

    // Comparar solo el mes del item con los filtros
    const monthMatches =
      mainDataFilters.month === "" || itemMonth === selectedMonth;
    const centerMatches =
      mainDataFilters.name_center === "" ||
      item.center_name === mainDataFilters.name_center;
    const clientMatches =
      mainDataFilters.clientId === "" ||
      item.client_id === mainDataFilters.clientId;

    return monthMatches && centerMatches && clientMatches;
  });
  useEffect(() => {
    setFilteredData2(filteredData);
  }, [invoices, mainDataFilters]); */
  const [filteredData2, setFilteredData2] = useState([]);
  const filteredData = invoices.filter((item) => {
    const formattedDate = new Date(item.date);
    const itemMonth = formattedDate.getUTCMonth() + 1; // Sumar 1 porque los meses van de 0 a 11
    const itemYear = formattedDate.getUTCFullYear();

    const selectedMonth = Number(mainDataFilters.month);
    const selectedYear = Number(mainDataFilters.year);

    const monthMatches =
      mainDataFilters.month === "" || itemMonth === selectedMonth;
    const yearMatches =
      mainDataFilters.year === "" || itemYear === selectedYear;
    const centerMatches =
      mainDataFilters.name_center === "" ||
      item.center_name === mainDataFilters.name_center;
    const clientMatches =
      mainDataFilters.clientId === "" ||
      item.client_id === mainDataFilters.clientId;

    return monthMatches && yearMatches && centerMatches && clientMatches;
  });

  useEffect(() => {
    setFilteredData2(filteredData);
  }, [invoices, mainDataFilters]);

  const downloadExcel = () => {
    // Convertir datos a un formato adecuado para xlsx
    const dataForExcel = (filteredData || [])?.map((item) => ({
      Centro: item.center_name,
      Paciente: item?.client ? capitalizarCadena(item?.client) : "Sin paciente",
      Fecha: DateCusmton(item.date),
      Valor: item?.amount,
      Comentario: item?.method,
      Estado: item?.status,
    }));

    // Crear una nueva hoja de trabajo
    const worksheet = utils.json_to_sheet(dataForExcel);

    // Crear un nuevo libro de trabajo
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "General Data");

    // Generar archivo Excel
    const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });

    // Crear un Blob a partir del buffer
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Crear enlace para descargar el archivo
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `facturas_Generales.xlsx`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const clearFilter = () => {
    setMainDataFilters({
      name_center: "",
      month: "",
      clientId: "",
      year: "",
    });
  };
  // para cambiar color de chip
  const getStatusStyles = (status) => {
    switch (status) {
      case "Pendiente":
        return { backgroundColor: "#ffe0b2" }; // Amarillo de fondo
      case "Cobrado":
        return { backgroundColor: "#c8e6c9" }; // Verde de fondo
      case "Atrasado":
        return { backgroundColor: "#ffcdd2" }; // Rojo claro de fondo
      default:
        return { backgroundColor: "#e0e0e0" };
    }
  };
  const [mainDataNewBill, setMainDataNewBill] = useState({
    center_id: "",
    client_id: "",
    name_rate: "",
    amount: "",
    date: "",
    status: "",
    method: "",
  });
  const [newBillError, setNewBillError] = useState({
    center_id: false,
    client_id: false,
    name_rate: false,
    amount: false,
    date: false,
    status: false,
    method: false,
  });
  const [mainDataNewRate, setMainDataNewRate] = useState({
    name: "",
    concurrence: "",
    price: "",
  });
  const [mainDataNewRateError, setMainDataNewRateError] = useState({
    name: false,
    concurrence: false,
    price: false,
  });
  const handleCloseModal = () => {
    setOpenModalNewBill(false);
    setMainDataNewBill({
      center_id: "",
      client_id: "",
      amount: "",
      date: "",
      status: "",
      method: "",
    });
    setMainDataNewRate({
      name: "",
      concurrence: "",
      price: "",
    });
    setShowAdditionalFields(false);
  };
  const [mainDataUpdateBill, setMainDataUpdateBill] = useState({
    center_id: "",
    client_id: "",
    name_rate: "",
    amount: "",
    date: "",
    status: "",
    method: "",
  });
  const [UpdateBillError, setUpdateBillError] = useState({
    client_id: false,
    name_rate: false,
    amount: false,
    date: false,
    status: false,
    method: false,
  });
  const handleCloseModalUpdate = async () => {
    setOpenModalUpdateBill(false);
    setMainDataUpdateBill({
      center_id: "",
      client_id: "",
      amount: "",
      date: "",
      status: "",
      method: "",
    });
    setMainDataNewRate({
      name: "",
      concurrence: "",
      price: "",
    });
    setShowAdditionalFields(false);
  };
  const SubmitmodalBill = async (e) => {
    e.preventDefault();

    if (showAdditionalFields === false) {
      if (!ValidateModalNewBill(mainDataNewBill, setNewBillError)) return;
      setOpenModalNewBill(false);
      setOpenbackd(true);

      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.response.invoices];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModal();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    } else {
      if (!ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError))
        return;
      if (!ValidateModalNewBill(mainDataNewBill, setNewBillError)) return;
      setOpenModalNewBill(false);
      setOpenbackd(true);

      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.response.invoices];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModal();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {});

      loadApi(`rate/${mainDataNewBill.center_id}`, true, "post", {
        ...mainDataNewRate,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    }
  };
  const SubmitmodalBillUpdate = async (e) => {
    e.preventDefault();

    if (showAdditionalFields === false) {
      if (!ValidateModalUpdateBill(mainDataUpdateBill, setUpdateBillError))
        return;
      setOpenModalUpdateBill(false);
      setOpenbackd(true);
      //falta endpoint de actualizar ingreso
      loadApi(`invoice/update_invoice/${idupdate}`, true, "put", {
        ...mainDataUpdateBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setInvoices((adata) =>
            adata.filter((aitem) => aitem.id !== idupdate)
          );
          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.Invoice];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModalUpdate();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    } else {
      if (!ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError))
        return;
      if (!ValidateModalUpdateBill(mainDataUpdateBill, setUpdateBillError))
        return;
      setOpenModalUpdateBill(false);
      setOpenbackd(true);

      loadApi(`invoice/update_invoice/${idupdate}`, true, "put", {
        ...mainDataUpdateBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
          setInvoices((adata) =>
            adata.filter((aitem) => aitem.id !== idupdate)
          );
          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.Invoice];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModalUpdate();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {});

      loadApi(`rate/${mainDataUpdateBill.center_id}`, true, "post", {
        ...mainDataNewRate,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    }
  };
  useEffect(() => {
    const options = (clients || []).map((item) => ({
      label: `${item.name} ${item.lastname}`,
      value: item.id,
    }));
    options.push({ label: "sin paciente", value: null });
    options.unshift({ label: "Todos los pacientes", value: "" });
    setMappedOptions(options);
  }, [clients]);
  const deleteBill = (id) => {
    setOpenbackd(true);
    loadApi(`invoice/delete/${id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success", 3000);
        //eliminar por id del
        setInvoices((adata) => adata.filter((aitem) => aitem.id !== id));
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <Grid container direction="row">
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Typography variant="h5" marginTop={3}>
              Ingresos Generales
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Stack
              py={2}
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
              sx={{
                justifyContent: { xs: "center", sm: "flex-end" },
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  display: { xs: "inherit", sm: "none" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },

                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white",
                  },
                }}
                onClick={() => {
                  navigate("/income");
                }}
              >
                <Typography color="black">General</Typography>
              </Button>
              <Button
                variant="text"
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },

                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white",
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80%", // Full width on extra small screens
                      sm: "auto", // Auto width on medium screens and up
                    },
                    borderBottom: "3px solid",
                    alignItems: "center",

                    pb: 1,
                    svg: {
                      color: "#fff!important",
                    },
                  }}
                  onClick={() => {
                    navigate("/income");
                  }}
                >
                  <Typography color="black">General</Typography>
                </Box>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  display: { xs: "inherit", sm: "none" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },

                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white",
                  },
                }}
                onClick={() => {
                  navigate("/income/info");
                }}
              >
                <Typography color="black">Detalles</Typography>
              </Button>
              <Button
                variant="text"
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  width: {
                    xs: "100%", // Full width on extra small screens
                    sm: "auto", // Auto width on medium screens and up
                  },
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "white", // Remove background color change
                    //opacity: 0.8, // Apply opacity change
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "80%", // Full width on extra small screens
                      md: "auto", // Auto width on medium screens and up
                    },
                    borderBottom: "3px solid",
                    alignItems: "center",

                    pb: 1,
                    svg: {
                      color: "#fff!important",
                    },
                  }}
                  onClick={() => {
                    navigate("/income/info");
                  }}
                >
                  <Typography color="black">Detalles</Typography>
                </Box>
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid container direction="row" marginTop={3} spacing={1}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Centro:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataFilters.name_center}
                  onChange={({ target }) =>
                    setMainDataFilters((adata) => ({
                      ...adata,
                      name_center: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los centros...</MenuItem>
                  {centers &&
                    (centers || []).map((row, index) => (
                      // recorrer centros row.id sera value y se mostrara row.name
                      <MenuItem value={row.name}>{row.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1, minWidth: { xs: 55, md: 40 } }}>
                Mes:
              </Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataFilters.month}
                  onChange={({ target }) =>
                    setMainDataFilters((adata) => ({
                      ...adata,
                      month: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los meses</MenuItem>
                  <MenuItem value="01">Enero</MenuItem>
                  <MenuItem value="02">Febrero</MenuItem>
                  <MenuItem value="03">Marzo</MenuItem>
                  <MenuItem value="04">Abril</MenuItem>
                  <MenuItem value="05">Mayo</MenuItem>
                  <MenuItem value="06">Junio</MenuItem>
                  <MenuItem value="07">Julio</MenuItem>
                  <MenuItem value="08">Agosto</MenuItem>
                  <MenuItem value="09">Septiembre</MenuItem>
                  <MenuItem value="10">Octubre</MenuItem>
                  <MenuItem value="11">Noviembre</MenuItem>
                  <MenuItem value="12">Diciembre</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1, minWidth: { xs: 55, md: 40 } }}>
                Año:
              </Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Autocomplete
                  size="small"
                  value={mainDataFilters.year}
                  onChange={(event, newValue) =>
                    setMainDataFilters({ ...mainDataFilters, year: newValue })
                  }
                  options={["", ...uniqueYears]} // Incluye opción para "Todos los años"
                  getOptionLabel={(option) =>
                    option === "" ? "Todos los años" : option.toString()
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Paciente:</Typography>

              <Autocomplete
                size="small"
                sx={{ width: { xs: "70%", sm: "85%", lg: "100%" } }}
                options={mappedOptions}
                getOptionLabel={(option) => option.label}
                value={
                  mappedOptions.find(
                    (option) => option.value === mainDataFilters.clientId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setMainDataFilters((prevFilters) => ({
                    ...prevFilters,
                    clientId: newValue ? newValue.value : "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ borderRadius: 100 }}
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button
                  fullWidth
                  sx={{
                    bgcolor: "secondary.main",
                    color: "primary.contrastText",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    ":hover": {
                      bgcolor: "#ebebeb",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    },
                  }}
                  onClick={clearFilter}
                >
                  Limpiar Filtros
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button
                  fullWidth
                  sx={{
                    bgcolor: "secondary.main",
                    color: "primary.contrastText",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    ":hover": {
                      bgcolor: "#ebebeb",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    },
                  }}
                  onClick={downloadExcel}
                >
                  <DownloadOutlinedIcon sx={{ marginInlineEnd: 1 }} /> Descargar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ m: 2 }} />
        <Stack>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setOpenModalNewBill(true);
            }}
            sx={{
              mb: 2,
              bgcolor: "secondary.main",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
            <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
              Nuevo Ingreso
            </Typography>
          </Button>
        </Stack>
        <Divider sx={{ mb: 2, mx: 2 }} />
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 2,
            borderRadius: 1,
            my: 1,
            display: { xs: "none", sm: "none", md: "inherit" },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Centro</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Paciente</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.4}
              lg={1.4}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "85%" }}>
                <Typography variant="body1">Fecha</Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.4}
              lg={1.4}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "85%" }}>
                <Typography variant="body1">Valor</Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.8}
              lg={1.8}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "90%" }}>
                <Typography variant="body1" noWrap>
                  Comentario
                </Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.5}
              lg={1.5}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                Estado
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.8}
              lg={1.8}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                Acciones
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {filteredData2?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">No hay facturas.</Alert>
          </Stack>
        )}
        {filteredData2 &&
          (filteredData2 || [])?.map((row, index) => {
            const statusStyles = getStatusStyles(row.status);
            return (
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  padding: 2,
                  borderRadius: 1,
                  my: 1,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Centro
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {capitalizarCadena(
                        row?.center_name !== null
                          ? row?.center_name
                          : "centro eliminado"
                      )}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Paciente
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {" "}
                      {!!row.client
                        ? capitalizarCadena(row?.client)
                        : "Sin Paciente"}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.4}
                    lg={1.4}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "85%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Fecha
                      </Typography>
                      <Typography variant="body1" fontWeight={400}>
                        {DateCusmton(row?.date)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.4}
                    lg={1.4}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "85%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Valor
                      </Typography>
                      <Typography variant="body1" fontWeight={400}>
                        $ {FormatearNumeroConSeparador(row?.amount)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.8}
                    lg={1.8}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "90%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Comentario
                      </Typography>
                      <Typography variant="body1" noWrap fontWeight={400}>
                        {row?.method ? row.method : "sin comentarios"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.5}
                    lg={1.5}
                    align="center"
                    fontWeight={400}
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Estado
                    </Typography>
                    <Chip label={row?.status} style={statusStyles} />
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.8}
                    lg={1.8}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <IconButton
                      aria-label="edit"
                      size="large"
                      color="secondary"
                      sx={{
                        marginRight: 1,
                        bgcolor: "primary.main",
                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        ":hover": {
                          bgcolor: "primary.main",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => {
                        setOpenModalUpdateBill({
                          id: row?.id,
                          center_id: row?.center_id,
                          client_id: row?.client_id,
                          center_name: row?.center_name,
                          name_rate: row?.name_rate,
                          amount: FormatearNumeroConSeparador(row?.amount),
                          status: row?.status,
                          date: row?.date,
                          method: row?.method,
                        });
                        setIdupdate(row?.id);
                      }}
                    >
                      <CreateOutlinedIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                      aria-label="delete"
                      size="large"
                      color="secondary"
                      sx={{
                        bgcolor: "#D32F2F!important",

                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        ":hover": {
                          bgcolor: "#D32F2F!important",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => {
                        setOpenModaldelete(row.id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalNewBillGeneral
        openModalNewBill={openModalNewBill}
        handleCloseModal={handleCloseModal}
        centers={centersfiltred}
        mainDataNewBill={mainDataNewBill}
        setMainDataNewBill={setMainDataNewBill}
        SubmitmodalBill={SubmitmodalBill}
        loadApi={loadApi}
        newBillError={newBillError}
        showAdditionalFields={showAdditionalFields}
        setShowAdditionalFields={setShowAdditionalFields}
        mainDataNewRate={mainDataNewRate}
        setMainDataNewRate={setMainDataNewRate}
        mainDataNewRateError={mainDataNewRateError}
        clients={clients}
      />
      <ModalUpdateBillGeneral
        openModalUpdateBill={openModalUpdateBill}
        handleCloseModalUpdate={handleCloseModalUpdate}
        centers={centers}
        mainDataUpdateBill={mainDataUpdateBill}
        setMainDataUpdateBill={setMainDataUpdateBill}
        SubmitmodalBillUpdate={SubmitmodalBillUpdate}
        loadApi={loadApi}
        UpdateBillError={UpdateBillError}
        showAdditionalFields={showAdditionalFields}
        setShowAdditionalFields={setShowAdditionalFields}
        mainDataNewRate={mainDataNewRate}
        setMainDataNewRate={setMainDataNewRate}
        mainDataNewRateError={mainDataNewRateError}
        clients={clients}
      />
      <ModalDeleteBillGeneral
        setOpenModaldelete={setOpenModaldelete}
        openModaldelete={openModaldelete}
        deleteBill={deleteBill}
      />
    </>
  );
}
function ValidateModalNewBill(mainDataNewBill, setNewBillError) {
  var final = false;
  const newAdata = {
    center_id: !mainDataNewBill.center_id,
    name_rate: !mainDataNewBill.name_rate,
    amount: !mainDataNewBill.amount,
    date: !mainDataNewBill.date,
    status: !mainDataNewBill.status,
  };

  setNewBillError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
function ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError) {
  var final = false;
  const newAdata = {
    name: !mainDataNewRate.name,
    concurrence: !mainDataNewRate.concurrence,
    price: !mainDataNewRate.price,
  };

  setMainDataNewRateError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
function ValidateModalUpdateBill(mainDataUpdateBill, setUpdateBillError) {
  var final = false;
  const newAdata = {
    center_id: !mainDataUpdateBill.center_id,
    name_rate: !mainDataUpdateBill.name_rate,
    amount: !mainDataUpdateBill.amount,
    date: !mainDataUpdateBill.date,
    status: !mainDataUpdateBill.status,
  };

  setUpdateBillError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
