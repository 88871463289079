import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Divider,
  Stack,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Autocomplete,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Alert,
  Paper,
  InputAdornment,
  InputLabel,
} from "@mui/material";

import GlobalHeaderAdmin from "../../components/headerAdmin";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import { write, utils } from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import capitalizarCadena from "../../utils/Cadena";
import dateToInput from "../../utils/DateToInput";
import DateCusmton from "../../utils/DateCusmton";
import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import { isFullWidth } from "validator";
export default function ProfessionalQuery() {
  const [photo_profile, setPhoto_profile] = useState();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [querys, setQuerys] = useState([]);
  const [mainDataAdmin, setMainDataAdmin] = useState({
    query: "",
  });
  const [mainDataAdminError, setMainDataAdminError] = useState({
    query: false,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!ValidateinputQuery(mainDataAdmin, setMainDataAdminError)) return;

    setOpenbackd(true);
    loadApi(`admin/query`, true, "post", {
      ...mainDataAdmin,
    })
      .then((response) => {
        showSnack(response.data.message, "success");

        setQuerys(response.data);
        setMainDataAdminError({
          query: false,
        });
      })
      .catch((e) => {
        showSnack(e.message, "error", 5000);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  return (
    <>
      <GlobalHeaderAdmin />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <Grid container direction="row" sx={{ mb: 2 }} spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} container alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: 600, color: "black" }}>
              Consulta SQL
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 2 }} />

        <Grid container spacing={2} direction="row" sx={{ mb: 2 }}>
          <Grid item xs={12} sm={12} md={10}>
            <TextField
              fullWidth
              multiline
              size="small"
              id="outlined-basic"
              // Eliminar el label
              rows={2}
              placeholder="Escribe una consulta SQL (select)"
              label=""
              variant="outlined"
              value={mainDataAdmin.query}
              onChange={(event) =>
                setMainDataAdmin((adata) => ({
                  ...adata,
                  query: event.target.value,
                }))
              }
              error={mainDataAdminError.query}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Button fullWidth onClick={handleSubmit}>
              consultar
            </Button>{" "}
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {querys?.headers?.map((header, index) => (
                  <TableCell key={index} align="center">
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {querys?.data?.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {querys?.headers?.map((header, cellIndex) => (
                    <TableCell key={cellIndex} align="center">
                      {row[header]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {querys?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">Sin Consulta.</Alert>
          </Stack>
        )}
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
function ValidateinputQuery(mainDataAdmin, setMainDataAdminError) {
  var final = false;
  const newAdata = {
    query: !mainDataAdmin.query,
  };

  setMainDataAdminError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
