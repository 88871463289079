import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

export default function ModalCommunications({
  openModalCom,
  setMainDataModalCom,
  mainDataModalCom,
  handleCloseModalCom,
  SubmitModalCommunications,
  mainDataModalComError,
  setMainDataModalComError,
}) {
  return (
    <>
      <Dialog
        open={!!openModalCom}
        onClose={() => {
          handleCloseModalCom();
          setMainDataModalComError({
            channel: false,
            text: false,
          });
        }}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden" }}
      >
        <DialogTitle>Enviar Mensaje</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box sx={{ minWidth: { md: 500 } }}>
            <Stack spacing={1}>
              <Alert severity="warning">
                Las comunicaciones por SMS están deshabilitadas. Contacta a tu
                administrador para habilitarlas.
              </Alert>
              {(!openModalCom?.hasEmail || openModalCom?.hasEmail === "") && (
                <Alert severity="warning">
                  Este paciente no tiene un correo electrónico registrado.{" "}
                  <Link
                    className="link"
                    href={`/client/${openModalCom.id}`}
                    color="inherit"
                  >
                    Haga clic aquí para agregar uno.
                  </Link>
                </Alert>
              )}
            </Stack>
            <Stack mb={2} mt={1.5}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Canal</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mainDataModalCom.channel}
                  sx={{ borderRadius: 2 }}
                  label="Canal"
                  onChange={({ target }) =>
                    setMainDataModalCom((adata) => ({
                      ...adata,
                      channel: target.value,
                    }))
                  }
                  required
                  error={mainDataModalComError.channel}
                >
                  <MenuItem value="Email" disabled={!openModalCom?.hasEmail}>
                    Correo
                  </MenuItem>
                  <MenuItem value="SMS" disabled={true}>
                    SMS
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack>
              <TextField
                id="outlined-textarea"
                label="Mensaje"
                placeholder="Escribe un mensaje"
                multiline
                InputProps={{
                  sx: { borderRadius: 2 },
                }}
                minRows={4}
                fullWidth
                value={mainDataModalCom.text}
                onChange={({ target }) =>
                  setMainDataModalCom((adata) => ({
                    ...adata,
                    text: target.value,
                  }))
                }
                error={mainDataModalComError.text}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={() => {
              setMainDataModalComError({
                channel: false,
                text: false,
              });
              handleCloseModalCom();
            }}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              SubmitModalCommunications();
            }}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
