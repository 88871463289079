import GroupsIcon from '@mui/icons-material/Groups';
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import LogoutIcon from '@mui/icons-material/Logout';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
const globalOptions = [

];

const headerOptions = {
    logged: [

        {
            title: "Inicio",
            icon: HolidayVillageIcon,
            route: "/calendar",
            roles: ["superadmin", "admin", "user"]
        },
        {
            title: "Centros",
            icon: AccountCircleIcon,
            route: "/profile",
            roles: ["superadmin", "admin", "user"]
        },
        {
            title: "Pacientes",
            icon: GroupsIcon,
            route: "/clients",
            roles: ["superadmin", "admin", "user"]
        },
        {
            title: "Ingresos",
            icon: BarChartIcon,
            route: "/income",
            roles: ["superadmin", "admin", "user"]
        },
        {
            title: "Reportes",
            icon: BarChartIcon,
            route: "/reports",
            roles: ["superadmin", "admin", "user"]
        },
        
        
        {
            title: "Cerrar Sesión",
            route: "/logout",
            icon: LogoutIcon
        },
    ],
    default: [
        ...globalOptions,
    ]
}

export default headerOptions;
