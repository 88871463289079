import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FormatearNumeroConSeparador from "../../utils/formatNumber";
import { useEffect } from "react";
export default function ModalRateUpdate({
  openModalUpdate,
  setOpenModalUpdate,
  setMainDataUpdate,
  mainDataUpdate,
  SubmitmodalUpdate,
  mainDataUpdateError,
  setMainDataUpdateError,
}) {
  useEffect(() => {
    setMainDataUpdate({
      name: openModalUpdate.name,
      concurrence: openModalUpdate.concurrence,
      price: openModalUpdate.price,
    });
  }, [openModalUpdate]);
  return (
    <>
      <Dialog
        open={!!openModalUpdate}
        onClose={() => {
          setOpenModalUpdate(false);
        }}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden" }}
      >
        <DialogTitle>Editar tarifa</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box sx={{ minWidth: { md: 500 }, p: 1 }}>
            <TextField
              label="Nombre Tarifa"
              InputProps={{
                sx: { borderRadius: 2, mb: 1 },
                endAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              id="name" // ID personalizado
              onChange={({ target }) =>
                setMainDataUpdate((adata) => ({ ...adata, name: target.value }))
              }
              value={mainDataUpdate?.name}
              error={mainDataUpdateError.name}
              required
              type="text"
              variant="outlined"
              fullWidth
            />
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Cadencia</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                sx={{ borderRadius: 2, mb: 1 }}
                label="cadence"
                value={
                  mainDataUpdate?.concurrence || openModalUpdate?.concurrence
                }
                error={mainDataUpdateError.concurrence}
                onChange={({ target }) =>
                  setMainDataUpdate((adata) => ({
                    ...adata,
                    concurrence: target.value,
                  }))
                }
              >
                <MenuItem value={"Mensual"}>Mensual</MenuItem>
                <MenuItem value={"Quincenal"}>Quincenal</MenuItem>
                <MenuItem value={"Por dia"}>Por dia</MenuItem>
                <MenuItem value={"Por hora"}>Por hora</MenuItem>
                <MenuItem value={"Por media hora"}>Por media hora</MenuItem>
                <MenuItem value={"Por paciente"}>Por paciente</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Valor"
              InputProps={{
                sx: { borderRadius: 2 },
              }}
              id="price" // ID personalizado
              onChange={(event) => {
                let val = FormatearNumeroConSeparador(event.target.value);
                setMainDataUpdate((adata) => ({
                  ...adata,
                  price: val,
                }));
              }}
              value={mainDataUpdate.price}
              error={mainDataUpdateError.price}
              type="text"
              variant="outlined"
              fullWidth
              required
              onKeyDown={(event) => {
                // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                if (
                  !/[0-9]/.test(event.key) &&
                  event.key !== "Backspace" &&
                  event.key !== "Delete" &&
                  event.key !== "ArrowLeft" &&
                  event.key !== "ArrowRight"
                ) {
                  event.preventDefault();
                }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={() => {
              setOpenModalUpdate(false);
              setMainDataUpdateError({
                name: false,
                concurrence: false,
                price: false,
              });
            }}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>

          <Button
            onClick={() => {
              SubmitmodalUpdate(openModalUpdate?.id, openModalUpdate?.index);
            }}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
