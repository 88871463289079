import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function ModalDeleteBill({
  setOpenModaldelete,
  openModaldelete,
  deleteBill,
}) {
  const handleCloseModaldelete = () => {
    setOpenModaldelete(null);
  };

  return (
    <>
      <Dialog
        open={!!openModaldelete}
        keepMounted
        onClose={handleCloseModaldelete}
      >
        <DialogTitle>{"Eliminar Ingreso"}</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 500 }}>
            <DialogContentText id="deleteBill">
              ¿Deseas eliminar este ingreso?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModaldelete}>Cancelar</Button>
          <Button
            color="error"
            onClick={() => {
              handleCloseModaldelete();
              deleteBill(openModaldelete);
            }}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
