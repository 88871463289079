import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

export default function ModalClient({
  openModal,
  handleCloseModal,
  mainData,
  setMainData,
  centers,
  Submit,
  mainDataError,
}) {
  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden", zIndex: "500 !important" }}
      >
        <DialogTitle>Agregar nuevo paciente</DialogTitle>

        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box sx={{ minWidth: { xs: "auto", md: 500 }, mt: 1 }}>
            <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
              {centers?.length < 1 && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    Aún no tienes centros, crea un nuevo centro{" "}
                    <Link className="link" href="/profile" color="inherit">
                      aquí
                    </Link>
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Centro</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    value={mainData.center_id}
                    onChange={({ target }) =>
                      setMainData((adata) => ({
                        ...adata,
                        center_id: target.value,
                      }))
                    }
                    label="Centro"
                    error={mainDataError?.center_id}
                    required
                  >
                    <MenuItem value="" disabled>
                      Seleccione una opción...
                    </MenuItem>
                    {centers &&
                      (centers || []).map((row, index) => (
                        // recorrer centros row.id sera value y se mostrara row.name
                        <MenuItem value={row?.id}>{row?.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Nombre"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  id="Name" // ID personalizado
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={({ target }) =>
                    setMainData((adata) => ({ ...adata, name: target.value }))
                  }
                  value={mainData?.name}
                  required
                  error={mainDataError?.name}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Apellidos"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  id="lastname" // ID personalizado
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      lastname: target.value,
                    }))
                  }
                  value={mainData?.lastname}
                  required
                  error={mainDataError?.lastname}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Cedula"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  id="national_id" // ID personalizado
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      national_id: target.value,
                    }))
                  }
                  value={mainData?.national_id}
                  required
                  error={mainDataError?.national_id}
                  onKeyDown={(event) => {
                    // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Correo electrónico"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  id="email" // ID personalizado
                  type="email"
                  variant="outlined"
                  fullWidth
                  onChange={({ target }) =>
                    setMainData((adata) => ({ ...adata, email: target.value }))
                  }
                  value={mainData?.email}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Celular"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  id="phone" // ID personalizado
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={({ target }) =>
                    setMainData((adata) => ({ ...adata, phone: target.value }))
                  }
                  value={mainData?.phone}
                  error={mainDataError?.phone}
                  onKeyDown={(event) => {
                    // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Stack marginBottom={2}>
              <TextField
                label="Dirección"
                InputProps={{
                  sx: { borderRadius: 2 },
                }}
                id="address" // ID personalizado
                type="text"
                variant="outlined"
                fullWidth
                onChange={({ target }) =>
                  setMainData((adata) => ({ ...adata, address: target.value }))
                }
                value={mainData?.address}
                error={mainDataError?.address}
              />
            </Stack>
            <Stack>
              <TextField
                id="outlined-textarea"
                label="Nota"
                placeholder="Notas acerca del paciente"
                InputProps={{
                  sx: { borderRadius: 2 },
                }}
                multiline
                rows={2}
                onChange={({ target }) =>
                  setMainData((adata) => ({ ...adata, note: target.value }))
                }
                value={mainData?.note}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={Submit}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
